import React, { useState, useEffect } from "react";
import {
  Input,
  Space,
  Typography,
  Dropdown,
  Checkbox,
  DatePicker,
  Tooltip,
} from "antd";
import IconButton from "components/ui/Button/IconButton";
import { ImagePaths } from "utils/ImagePath";
import { SearchOutlined } from "@ant-design/icons";
import MyButton from "components/ui/Button/MyButton";
import { getPrintData, getCSVData } from "./CommonFuntion";

const CommonTblHeader = ({
  pageName,
  addVisible,
  editVisible,
  addComponent,
  pageInfo,
  handleSearch,
  selectedDetails,
  addClick,
  handleFilter,
  columns,
  handleDraft,
  onStateChange,
  isDraft,
  handleBackToHome,
  url,
  query,
  addBtnLabel,
  isAddRequired = true,
  isDraftRequired = true,
  isDateRequired = false,
  approvalFilter = false,
  handleDateChange,
  selectedFilterData,
  selectedFilterVal,
  handleFilterClick,
  selectedDate,
  format,
  picker,
  testID,
  isBackRequired = false,
  isFltRequired = true,
  isPrintRequired = true,
  isExcelRequired = true,
  isContentRequired = false,
  contentToShow = "",
  isToDateRequired = false,
  handleToDateChange,
  selectedToDate,
  fromDateTlt = "",
  toDateTlt = "",
  disabledFromDate = null,
  disabledToDate = null,
  btndisabled = false,
  extras,
}) => {
  const [searchText, setSearchText] = useState("");
  const [column, setColumn] = useState(columns);
  const [draggedItem, setDraggedItem] = useState(null);
  const [reorderedColumns, setReorderedColumns] = useState([]);
  
  useEffect(() => {
    onStateChange(searchText, reorderedColumns);
  }, [reorderedColumns, searchText, onStateChange]);

  const handleDragStart = (index) => {
    setDraggedItem(index);
  };

  const handleDragOver = (index) => {
    const draggedOverItem = index;
    if (draggedItem === draggedOverItem) {
      return;
    }
    const newItems = [...column];
    newItems.splice(draggedItem, 1);
    newItems.splice(draggedOverItem, 0, column[draggedItem]);
    let filteredColumn = newItems.filter((item) => !item.hidden);
    setColumn(newItems);
    setReorderedColumns(filteredColumn);
    setDraggedItem(draggedOverItem);
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
  };

  const handleCheck = (value, index) => {
    let list = [...column];
    list[index]["hidden"] = !value;
    let filteredColumn = list.filter((item) => !item.hidden);
    setColumn(list);
    setReorderedColumns(filteredColumn);
  };

  const items = column.map((item, index) => ({
    key: index,
    label: (
      <div
        style={{ fontSize: "0.85vw", fontFamily: "Urbanist" }}
        className="flex justify-between items-center"
        draggable
        onDragStart={() => handleDragStart(index)}
        onDragOver={() => handleDragOver(index)}
        onDragEnd={handleDragEnd}
      >
        <Checkbox
          data-testid={`${testID}_${item?.title?.split(" ")[0]?.slice(0, 4)}`}
          checked={!item.hidden}
          onChange={(e) => handleCheck(e.target.checked, index)}
        >
          {item.title}
        </Checkbox>
        <IconButton
          title=""
          icon={<img src={ImagePaths.dragIcon.default} alt="dragIcon" />}
        />
      </div>
    ),
  }));
  // table option drag-drop and hide-show columns end

  return (
    <div className={"h-full"}>
      <div
        className="flex justify-between items-center"
        style={{ width: addVisible || editVisible ? "100%" : "" }}
      >
        {/*draft panel  */}
        {addVisible ||
        (editVisible && Object.keys(selectedDetails).length > 0) ? (
          addComponent && typeof addComponent === "function" ? (
            addComponent()
          ) : null
        ) : isDraft ? (
          <div className="flex justify-start items-center cursor-pointer">
            <Tooltip title={"Back"} placement="bottom">
              <img
                src={ImagePaths.backArrow.default}
                alt="Back"
                className="commonTblBackIcon"
                onClick={handleBackToHome}
              />
            </Tooltip>
            <p className="common_addTltTxt">
              {pageInfo?.totalCount
                ? `Draft (${pageInfo.totalCount})`
                : "Draft"}
            </p>
          </div>
        ) : (
          <div className="flex items-start flex-col h-full">
            {isBackRequired ? (
              <Tooltip title={"Back"} placement="bottom">
                <img
                  src={ImagePaths.backArrow.default}
                  alt="Back"
                  className="commonTblBackIcon"
                  onClick={handleBackToHome}
                />
              </Tooltip>
            ) : null}

            {/* page title */}
            <p className="common_tltTxt">
              {pageInfo?.totalCount
                ? `${pageName} (${pageInfo.totalCount})`
                : pageName}
            </p>
            <div className="flex gap-2">
              {/* data table options */}
              {isDraft ? null : (
                <Space size={"middle"}>
                  {isFltRequired ? (
                    <IconButton
                      testID={`${testID}_flt`}
                      title="Filter"
                      icon={
                        <img src={ImagePaths.filter.default} alt="Filter" />
                      }
                      className="w-6 h-6"
                      onClick={handleFilter}
                    />
                  ) : null}
                  <Dropdown
                    data-testid={`${testID}_drop`}
                    menu={{ items }}
                    trigger={["click"]}
                    placement="bottomLeft"
                    overlayStyle={styles.dropOverLay}
                  >
                    <IconButton
                      title="Column Reorder"
                      icon={
                        <img
                          src={ImagePaths.alignColumn.default}
                          alt="Column Reorder"
                        />
                      }
                      className="w-6 h-6"
                    />
                  </Dropdown>
                  {isExcelRequired ? (
                    <IconButton
                      testID={`${testID}_download`}
                      title="Download"
                      icon={
                        <img src={ImagePaths.export.default} alt="Download" />
                      }
                      className="w-6 h-6"
                      onClick={() => getCSVData(url, query)}
                    />
                  ) : null}
                  {isPrintRequired ? (
                    <IconButton
                      testID={`${testID}_print`}
                      title="Print"
                      icon={<img src={ImagePaths.print.default} alt="Print" />}
                      className="w-6 h-6"
                      onClick={() => getPrintData(url, query)}
                    />
                  ) : null}
                  {isDraftRequired ? (
                    <IconButton
                      testID={`${testID}_draft`}
                      title="Draft"
                      icon={<img src={ImagePaths.draft.default} alt="Draft" />}
                      className="w-6 h-6"
                      onClick={handleDraft}
                    />
                  ) : null}
                </Space>
              )}

              {/* options for approval */}
              {!approvalFilter ? null : (
                <div className="flex gap-1">
                  {selectedFilterData.map((item) => {
                    const count = extras?.data?.extras[item] || 0;

                    return (
                      <MyButton
                        key={item}
                        label={`${item} (${count})`}
                        className={`${
                          selectedFilterVal === item
                            ? "selectedFilterBtn h-[80%]"
                            : "unselectedFilterBtn h-[80%]"
                        }`}
                        onClick={() => handleFilterClick(item)} // Update state when clicked
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}

        <div className="flex justify-center items-center">
          {/* this three  buttons are for conditional based renders */}

          {/* is date */}
          {isDateRequired && (
            <div className="flex flex-col justify-center items-start mt-2 mr-5">
              {fromDateTlt ? (
                <div className="commonInputTlt">{fromDateTlt}</div>
              ) : null}
              <DatePicker
                bordered={true}
                size="large"
                data-testid={`${testID}_datepicker`}
                onChange={handleDateChange}
                value={selectedDate}
                format={format ? format : "DD MMM YYYY"}
                className={fromDateTlt ? "" : "mt-3"}
                style={styles.dateInput}
                {...(picker && { picker })}
                allowClear={false}
                disabledDate={disabledFromDate}
              />
            </div>
          )}

          {/* is to date */}
          {isToDateRequired && (
            <div className="flex flex-col justify-center items-start mt-2 mr-5">
              {toDateTlt ? (
                <div className="commonInputTlt">{toDateTlt}</div>
              ) : null}
              <DatePicker
                bordered={true}
                size="large"
                data-testid={`${testID}_datepicker2`}
                onChange={handleToDateChange}
                value={selectedToDate}
                format={format ? format : "DD MMM YYYY"}
                className={toDateTlt ? "" : "mt-3"}
                style={styles.dateInput}
                {...(picker && { picker })}
                allowClear={false}
                disabledDate={disabledToDate}
              />
            </div>
          )}

          {/* content display section */}
          {isContentRequired && contentToShow.length > 0 && (
            <div className="relative top-4 mr-3 commonTblContentTxt">
              {contentToShow}
            </div>
          )}

          {/* this is a fixed display for header */}
          {/* search and request section */}
          {addVisible || editVisible || isDraft ? null : (
            <Space size={"middle"} className="relative top-2">
              <div className="flex justify-between flex-col">
                <div className="flex flex-col gap-2 sm:flex-row">
                  {/* search bar */}
                  <div className="w-full sm:flex-auto">
                    <Input
                      data-testid={`${testID}_srch`}
                      className="w-full sm:flex-auto"
                      style={styles.searchInput}
                      placeholder="Search"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      onPressEnter={(e) => handleSearch(e)}
                      onKeyDown={(e) => {
                        if (
                          !/^[a-zA-Z0-9\s]*$/.test(e.key) &&
                          e.key !== "Enter"
                        ) {
                          e.preventDefault();
                        }
                      }}
                      suffix={
                        <div className="border-l-2 border-[#183433]">
                          <div className="m-1">
                            <SearchOutlined
                              style={styles.searchIcon}
                              className="site-form-item-icon p-1 w-6 h-6"
                              onClick={(e) => handleSearch(e)}
                            />
                          </div>
                        </div>
                      }
                    />
                    {!isAddRequired ? (
                      <Typography style={styles.countTxt}>
                        {`Showing ${
                          pageInfo?.pageSize ? pageInfo?.pageSize : 0
                        } out of ${
                          pageInfo?.totalCount ? pageInfo?.totalCount : 0
                        }`}
                      </Typography>
                    ) : null}
                  </div>

                  {/* Divider */}
                  <div className="hidden sm:block w-px h-8 bg-gray-300"></div>
                  {/* Vertical Divider for larger screens */}
                  <div className="block sm:hidden w-full h-px bg-gray-300 my-4"></div>
                  {/* Horizontal Divider for smaller screens */}

                  {/* new req */}
                  {isAddRequired && (
                    <div className="flex justify-end mt-4 sm:mt-0 sm:mr-2">
                      <MyButton
                        testID={`${testID}_${
                          addBtnLabel ? addBtnLabel : "Add"
                        }`}
                        onClick={addClick}
                        label={addBtnLabel ? addBtnLabel : "Add"}
                        disabled={btndisabled}
                        className={`w-full sm:w-auto h-full ${
                          btndisabled ? "cursor-not-allowed opacity-50" : ""
                        }`}
                      />
                    </div>
                  )}
                </div>

                {/* count */}
                {isAddRequired && (
                  <div className="mt-2 mr-2 sm:mt-1 sm:flex sm:justify-end">
                    <Typography style={styles.countTxt}>
                      {`Showing ${
                        pageInfo?.pageSize ? pageInfo?.pageSize : 0
                      } out of ${
                        pageInfo?.totalCount ? pageInfo?.totalCount : 0
                      }`}
                    </Typography>
                  </div>
                )}
              </div>
            </Space>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  searchInput: {
    width: "20vw",
    border: "1px solid #616161",
    borderRadius: "0.2vw",
    outline: "none",
    marginTop: "0vw",
  },
  searchIcon: {
    color: "#616161",
    cursor: "pointer",
    border: "1px solid #183433",
    fontSize: 12,
    borderRadius: 4,
  },
  countTxt: {
    color: "#183433",
    fontSize: "0.75vw",
    fontWeight: 600,
  },
  dropOverLay: {
    maxHeight: 250,
    overflowY: "auto",
  },
  dateInput: {
    width: "8.5vw",
    border: "1px solid #616161",
    borderRadius: "0.2vw",
    outline: "none",
    fontFamily: "Urbanist",
    fontWeight: 500,
    padding: "2px 8px",
  },
};

export default CommonTblHeader;
