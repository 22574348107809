import React, { useState, useEffect, useCallback, useRef } from 'react';
import MyButton from 'components/ui/Button/MyButton';
import { Form, Input, Card, Checkbox, TimePicker, Tooltip } from 'antd';
import { EMPLOYEE_SHIFT } from 'constants';
import { noBlankSpacesValidator, areValuesNotEmptyExceptKeys } from 'components/common/validation';
import { useNavigate, useLocation } from 'react-router-dom';
import { ImagePaths } from "utils/ImagePath";
import moment from 'moment';
import dayjs from 'dayjs';
import ToastMsg from "components/common/ToastMsg";
import { CommonCancelPopup } from "components/common/CommonComponent";

export const AddEmployeeShift = () => {
  const [formData, setFormData] = useState({
    code: '',
    shift_name: '',
    shift_timings_from: '',
    shift_timings_to: '',
    total_shift_hrs: '',
    total_break_hrs: null,
    break_1st_start_time: null,
    break_1st_end_time: null,
    lunch_start_time: null,
    lunch_end_time: null,
    break_2nd_start_time: null,
    break_2nd_end_time: null,
    notes: '',
    is_active: true,
  });
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [timeDifference, setTimeDifference] = useState(null);
  const [loadings, setLoadings] = useState(false);
  const [isCancelClk, setIsCancelClk] = useState(false);
  const todayDate = dayjs().format('YYYY-MM-DD');

  const prevPropsRef = useRef();

  const navigate = useNavigate();
  const location = useLocation();
  const { fromEdit, editDetails, isActive } = location.state || {};
  const [form] = Form.useForm();

  useEffect(() => {
    if (editDetails !== null &&
      Object.keys(editDetails).length !== 0 &&
      JSON.stringify(prevPropsRef.current) !== JSON.stringify(editDetails) &&
      JSON.stringify(formData) !== JSON.stringify(editDetails)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        id: editDetails.id,
        code: editDetails.code ? editDetails.code : "",
        notes: editDetails.notes ? editDetails.notes : "",
        is_active: editDetails.is_active,
        shift_name: editDetails.shift_name ? editDetails.shift_name : "",
        shift_timings_from: editDetails.shift_timings_from ? dayjs(`${todayDate}T${dayjs(editDetails.shift_timings_from, 'h:mm A').format('HH:mm:ss')}`)  : "",
        shift_timings_to: editDetails.shift_timings_to ? dayjs(`${todayDate}T${dayjs(editDetails.shift_timings_to, 'h:mm A').format('HH:mm:ss')}`) : "",
        total_shift_hrs: editDetails.total_shift_hrs ? editDetails.total_shift_hrs : "",
        total_break_hrs: editDetails.total_break_hrs ? dayjs(`${todayDate}T${editDetails.total_break_hrs}`) : null,
        break_1st_start_time: editDetails.break_1st_start_time ? dayjs(`${todayDate}T${editDetails.break_1st_start_time}`) : null,
        break_1st_end_time: editDetails.break_1st_end_time ? dayjs(`${todayDate}T${editDetails.break_1st_end_time}`) : null,
        lunch_start_time: editDetails.lunch_start_time ? dayjs(`${todayDate}T${editDetails.lunch_start_time}`) : null,
        lunch_end_time: editDetails.lunch_end_time ? dayjs(`${todayDate}T${editDetails.lunch_end_time}`) : null,
        break_2nd_start_time: editDetails.break_2nd_start_time ? dayjs(`${todayDate}T${editDetails.break_2nd_start_time}`) : null,
        break_2nd_end_time: editDetails.break_2nd_end_time ? dayjs(`${todayDate}T${editDetails.break_2nd_end_time}`) : null,
      }));
      setStartTime(editDetails.shift_timings_from ? dayjs(`${todayDate}T${dayjs(editDetails.shift_timings_from, 'h:mm A').format('HH:mm:ss')}`)  : null);
      setEndTime(editDetails.shift_timings_to ? dayjs(`${todayDate}T${dayjs(editDetails.shift_timings_to, 'h:mm A').format('HH:mm:ss')}`) : null);
      setTimeDifference(editDetails.total_shift_hrs ? editDetails.total_shift_hrs : null);
      form.setFieldsValue({
        code: editDetails.code ? editDetails.code : "",
        notes: editDetails.notes ? editDetails.notes : "",
        is_active: editDetails.is_active,
        shift_name: editDetails.shift_name ? editDetails.shift_name : "",
        shift_timings_from: editDetails.shift_timings_from ? dayjs(`${todayDate}T${dayjs(editDetails.shift_timings_from, 'h:mm A').format('HH:mm:ss')}`)  : "",
        shift_timings_to: editDetails.shift_timings_to ? dayjs(`${todayDate}T${dayjs(editDetails.shift_timings_to, 'h:mm A').format('HH:mm:ss')}`) : "",
        total_shift_hrs: editDetails.total_shift_hrs ? editDetails.total_shift_hrs : "",
        total_break_hrs: editDetails.total_break_hrs ? dayjs(`${todayDate}T${editDetails.total_break_hrs}`) : null,
        break_1st_start_time: editDetails.break_1st_start_time ? dayjs(`${todayDate}T${editDetails.break_1st_start_time}`) : null,
        break_1st_end_time: editDetails.break_1st_end_time ? dayjs(`${todayDate}T${editDetails.break_1st_end_time}`) : null,
        lunch_start_time: editDetails.lunch_start_time ? dayjs(`${todayDate}T${editDetails.lunch_start_time}`) : null,
        lunch_end_time: editDetails.lunch_end_time ? dayjs(`${todayDate}T${editDetails.lunch_end_time}`) : null,
        break_2nd_start_time: editDetails.break_2nd_start_time ? dayjs(`${todayDate}T${editDetails.break_2nd_start_time}`) : null,
        break_2nd_end_time: editDetails.break_2nd_end_time ? dayjs(`${todayDate}T${editDetails.break_2nd_end_time}`) : null,
      });
      prevPropsRef.current = editDetails;
    }
  }, [editDetails, form, formData, todayDate])

  const handleChange = (name, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
    form.setFieldsValue({
      ...formData,
      [name]: value,
    });
  };

  const onToChange = (value, timeString) => {
    form.setFieldValue("total_shift_hrs", "")
    setFormData(prevFormData => ({
      ...prevFormData,
      shift_timings_to: value,
      total_break_hrs: null,
      break_1st_start_time: null,
      break_1st_end_time: null,
      lunch_start_time: null,
      lunch_end_time: null,
      break_2nd_start_time: null,
      break_2nd_end_time: null,
    }));
    form.setFieldValue("shift_timings_to", value);
    setEndTime(value);
    if (startTime && value) {
      calculateTimeDifference(startTime, value);
    }
  };

  const onFromChange = (value, timeString) => {
    form.setFieldValue("total_shift_hrs", "")
    setFormData(prevFormData => ({
      ...prevFormData,
      shift_timings_from: value,
      total_break_hrs: null,
      break_1st_start_time: null,
      break_1st_end_time: null,
      lunch_start_time: null,
      lunch_end_time: null,
      break_2nd_start_time: null,
      break_2nd_end_time: null,
    }));
    form.setFieldValue("shift_timings_from", value);
    setStartTime(value);
    if (endTime && value) {
      calculateTimeDifference(value, endTime);
    }
  };

  const handleTimeChange = (value, timeString, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
    form.setFieldsValue({
      ...formData,
      [name]: value,
    });
  }

  const calculateTimeDifference = (start, end) => {

    var diff;
    if (end < start) {
      end = dayjs(end).add(1, 'day');
    }

    start = Math.floor(start / 1000) * 1000;
    end = Math.floor(end / 1000) * 1000;
    diff = end - start;
    var msec = diff;
    var hh = `0${Math.floor(msec / 1000 / 60 / 60)}`;
    msec -= hh * 1000 * 60 * 60;

    var mm = `0${Math.floor(msec / 1000 / 60)}`;
    msec -= mm * 1000 * 60;

    var ss = `0${Math.floor(msec / 1000)}`;
    msec -= ss * 1000;

    if (parseInt(hh) < 0 || hh === "00" || hh === "0NaN") {
      // End time cannot be earlier than start time
      setTimeDifference(null);
      setFormData(prevFormData => ({
        ...prevFormData,
        total_shift_hrs: ""
      }));
      form.setFieldValue("total_shift_hrs", "")
      return;
    }
    const totalShiftHrs = `${hh.slice(-2)}:${mm.slice(-2)}:00`;
    setFormData(prevFormData => ({
      ...prevFormData,
      total_shift_hrs: totalShiftHrs
    }));
    setTimeDifference(totalShiftHrs);
    form.setFieldValue("total_shift_hrs", totalShiftHrs);
  }

  const renderInput = (label, statekey, testID = "", maxLength = 150, required = false) => {
    return (
      <div>
        <div className='commonInputTlt'>{label}{required ? <span className='requiredTxt'> *</span> : null}</div>
      <Input
        autoComplete='off'
        style={statekey === "total_shift_hrs" ? styles.timeField : styles.textField}
        disabled={statekey === "code" || statekey === "total_shift_hrs"}
        type={"text"}
        name={statekey}
        value={formData[statekey] ? formData[statekey] : ""}
        maxLength={maxLength}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        data-testid={testID}
      />
      </div>
    )
  }

  const renderTimePicker = (label, statekey, testID = '', valueOnChange, required = false) => {
    return (
      <div>
        <div className='commonInputTlt'>{label}{required ? <span className='requiredTxt'> *</span> : null}</div>
      <TimePicker
        size="large"
        name={statekey}
        value={formData[statekey] ? formData[statekey] : ""}
        onChange={valueOnChange ? valueOnChange : (time, timeString) => handleTimeChange(time, timeString, statekey)}
        use12Hours={statekey === "total_break_hrs" ? false : true}
        format={statekey === "total_break_hrs" ? "HH:mm" : "h:mm A"}
        style={(statekey === "total_break_hrs" || statekey === "shift_timings_from" || statekey === "shift_timings_to")
          ? styles.timeField : styles.timeBreakField}
        allowClear={true}
        inputReadOnly={true}
        data-testid={testID}
        disabledTime={statekey === "total_break_hrs" ? disabledTotalBreakHrs : null}
        {...(label === "Start Time" || label === "End Time" ? (startTime && endTime) ? disabledTime() : null : null)}
        placeholder = ""
      />
      </div>
    )
  }

  // const disabledTime = (current, range) => {
  //   const [start, end] = range;
  //   const disabledHourOptions = [];
  //   let startHour = start.hour();
  //   let endHour = end.hour();

  //   for (let h = 0; h <= 23; h++) {
  //     if (endHour < startHour) {
  //       // Range spans across two days
  //       if (h <= startHour && h >= endHour) {
  //         disabledHourOptions.push(h);
  //       }
  //     } else {
  //       // Range falls within the same day
  //       if (h <= startHour || h >= endHour) {
  //         disabledHourOptions.push(h);
  //       }
  //     }
  //   }
  //   return {
  //     disabledHours: () => disabledHourOptions,
  //   };
  // };

  // const disabledTotalBreakHrs = () => {
  //   const disabledHours = [];
  //   if (timeDifference) {
  //     const time = moment(timeDifference, "HH:mm:ss").format("H");
  //     for (let i = 0; i < 24; i++) {
  //       if (i >= time && i <= 23) {
  //         disabledHours.push(i);
  //       }
  //     }
  //   }
  //   return {
  //     disabledHours: () => disabledHours,
  //   };
  // };

  const disabledTime = () => {
    const disabledHours = [];
    const disabledMinutes = [];
    
    // Ensure startTime and endTime are available
    if (startTime && endTime) {
      const startHour = dayjs(startTime).hour();
      const startMinute = dayjs(startTime).minute();
      const endHour = dayjs(endTime).hour();
      const endMinute = dayjs(endTime).minute();
  
      // Disable all hours before the start hour
      for (let i = 0; i < startHour; i++) {
        disabledHours.push(i);
      }
  
      // Disable all hours after the end hour
      for (let i = endHour + 1; i < 24; i++) {
        disabledHours.push(i);
      }
  
      return {
        disabledHours: () => disabledHours,
        disabledMinutes: (selectedHour) => {
          if (selectedHour === startHour) {
            // Disable minutes before the start minute
            return Array.from({ length: startMinute }, (_, i) => i); // [0, 1, 2, ..., startMinute - 1]
          }
          if (selectedHour === endHour) {
            // Disable minutes after the end minute
            return Array.from({ length: 60 }, (_, i) => (i > endMinute ? i : null)).filter(Number);
          }
          return []; // No minutes disabled for other hours
        },
      };
    }
  
    return {};
  }

  const disabledTotalBreakHrs = () => {
    const disabledHours = [];
    let maxAllowedBreakHour, maxAllowedBreakMinutes;
    if (timeDifference) {
      const [hours, minutes] = timeDifference.split(':').map(Number);
      maxAllowedBreakHour = hours;
      maxAllowedBreakMinutes = minutes;

      for (let i = 0; i < 24; i++) {
        if (i > maxAllowedBreakHour) {
          disabledHours.push(i);
        }
      }

      return {
        disabledHours: () => disabledHours,
        disabledMinutes: (selectedHour) => {
          if (selectedHour === maxAllowedBreakHour) {
            const disabledMins = [];
            for (let i = maxAllowedBreakMinutes + 1; i < 60; i++) {
              disabledMins.push(i);
            }
            return disabledMins;
          }
          return [];
        },
      };
    }
    return {};
  };
  

  const handleBackCancel = useCallback(() => {
    navigate('/configuration/shiftconfiguration');
  }, [navigate])

  const handleCancelBtn = () => {
    setIsCancelClk(false);
    handleBackCancel();
  }

  const handleSubmit = useCallback((isDraftClked) => {
    let methods = fromEdit ? "PUT" : "POST";
    const authtoken = sessionStorage.getItem("token")
    // timeDifference.toString()
    setLoadings(true)
    try {
      setLoadings(true)
      let updatedFormData = {
        ...formData,
        shift_timings_from: startTime ? moment.unix(startTime / 1000).format('HH:mm:ss') : formData.shift_timings_from ? dayjs(formData.shift_timings_from).format('HH:mm:ss') : null,
        shift_timings_to: endTime ? moment.unix(endTime / 1000).format('HH:mm:ss') : formData.shift_timings_to ? dayjs(formData.shift_timings_to).format('HH:mm:ss') : null,
        total_shift_hrs: formData.total_shift_hrs ? moment(formData.total_shift_hrs, 'h:mm A').format('HH:mm:ss') : null,
        total_break_hrs: formData.total_break_hrs ? dayjs(formData.total_break_hrs).format('HH:mm:ss') : null,
        break_1st_start_time: formData.break_1st_start_time ? dayjs(formData.break_1st_start_time).format('HH:mm:ss') : null,
        break_1st_end_time: formData.break_1st_end_time ? dayjs(formData.break_1st_end_time).format('HH:mm:ss') : null,
        lunch_start_time: formData.lunch_start_time ? dayjs(formData.lunch_start_time).format('HH:mm:ss') : null,
        lunch_end_time: formData.lunch_end_time ? dayjs(formData.lunch_end_time).format('HH:mm:ss') : null,
        break_2nd_start_time: formData.break_2nd_start_time ? dayjs(formData.break_2nd_start_time).format('HH:mm:ss') : null,
        break_2nd_end_time: formData.break_2nd_end_time ? dayjs(formData.break_2nd_end_time).format('HH:mm:ss') : null,
      };
      if (isDraftClked) {
        updatedFormData = { ...updatedFormData, is_draft: true };
      } else {
        updatedFormData = { ...updatedFormData, is_draft: false };
      }
      fetch(EMPLOYEE_SHIFT, {
        method: methods,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `token ${authtoken}`
        },
        body: JSON.stringify(updatedFormData)
      })
        .then((response) => response.json())
        .then(async data => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            handleBackCancel();
            setLoadings(false);
            setFormData({
              code: '',
              leave_name: '',
              no_of_days: 0,
              notes: '',
              leave_expiry: '',
              leave_carry_forward: false,
              is_active: true,
            })
          }
          else if (data.status === "fail") {
            setLoadings(false);
            ToastMsg("error", data.message);
          }
        })
        .catch(error => {
          ToastMsg("error", error.message);
        });
    } catch (error) {
      ToastMsg("error", error.message);
    } finally {
      setIsCancelClk(false);
    }
  });
  
  const checkAllMandatory = useCallback((isDraftClked) => {
    if (!isDraftClked) {
      form
        .validateFields()
        .then(() => {
          handleSubmit(false);
        })
        .catch(error => {
          console.error("Validation Error:", error);
        });
    } else {
      handleSubmit(true);
    }
  }, [form, handleSubmit]);

  const handleSave = useCallback(() => {
    checkAllMandatory(false);
  }, [checkAllMandatory]);

  const handleSaveAsDraft = useCallback(() => {
    checkAllMandatory(true);
  }, [checkAllMandatory]);

  const keysToExclude = ['is_active', 'company'];
  const isDataAvail = areValuesNotEmptyExceptKeys(formData, keysToExclude);

  return (
    <Card className='h-full'>
      <div className='h-1/6 flex justify-between items-center p-1 ' style={{ borderBottom: "1px solid #D6D6D6" }}>
        <div className='flex items-center'>
          <Tooltip title={"Back"} placement="bottom">
            <img src={ImagePaths.backArrow.default} alt="Back" className='commonTblBackIcon' onClick={handleBackCancel} />
          </Tooltip>
          <p className='common_addTltTxt'>{fromEdit ? "Edit Shift" : "Add Shift"}</p>
        </div>
        <Checkbox
          disabled = {fromEdit ? !isActive : true }
          checked={formData.is_active}
          onChange={(e) => handleChange("is_active", e.target.checked)}
          data-testid={'sft_sts'}
        >
          Active
        </Checkbox>
      </div>
      <div className='h-5/6' style={{ margin: "16px 0px" }}>
        <Form
          layout="inline"
          form={form}
          colon={false}
          requiredMark={false}
          className='w-full'
          name="basicform"
          initialValues={formData}
          onFinish={handleSave}
          onSubmit={(e) => e.preventDefault()}
          data-testid={'sft_form'}
        >
          <div className='w-full flex justify-between items-center m-1'>
            {fromEdit ? <Form.Item
              style={styles.formItem}
              name="code"
              rules={[{ required: true, message: "This field is required" },
              { validator: noBlankSpacesValidator }
              ]}
            >
              {renderInput('Shift Code', "code", 'sft_code', 10, true)}
            </Form.Item> : null}
            <Form.Item
              style={styles.formItem}
              name="shift_name"
              rules={[{ required: true, message: "This field is required" },
              { validator: noBlankSpacesValidator }
              ]}
            >
              {renderInput('Shift Name', "shift_name", 'sft_name', 50, true)}
            </Form.Item>
            <Form.Item
              style={styles.formItem}
              name="shift_timings_from"
              rules={[{ required: true, message: "This field is required" }]}
            >
              {renderTimePicker("Shift Start Time", "shift_timings_from", 'sft_from', onFromChange, true)}
            </Form.Item>
            <Form.Item
              style={styles.formItem}
              name="shift_timings_to"
              rules={[{ required: true, message: "This field is required" }]}
            >
              {renderTimePicker("Shift End Time", "shift_timings_to", 'sft_to', onToChange, true)}
            </Form.Item>
            {!fromEdit ? <>
              <Form.Item
                style={styles.formItem}
                name="total_break_hrs"
                rules={[{ required: true, message: "This field is required" }]}
              >
                {renderTimePicker("Total Break Hours", "total_break_hrs", 'sft_tbh', "", true)}
              </Form.Item>
              <Form.Item style={styles.formItem} name="total_shift_hrs"
              >
                {renderInput("Total Shift Hours", "total_shift_hrs", 'sft_tsh')}
              </Form.Item>
            </> : null}
          </div>
           <div className='w-full flex justify-between items-center m-1'>
            { fromEdit ? <>
            <Form.Item
              style={styles.formItem}
              name="total_break_hrs"
              rules={[{ required: true, message: "This field is required" }]}
            >
              {renderTimePicker("Total Break Hours", "total_break_hrs", 'sft_tbh', "", true)}
            </Form.Item>
            <Form.Item style={styles.formItem} name="total_shift_hrs"
            >
              {renderInput("Total Shift Hours", "total_shift_hrs", 'sft_tsh')}
            </Form.Item></> : null}
            <Form.Item name="notes" style={styles.formItem}>
              {renderInput('Notes', "notes", 'sft_notes', 150)}
            </Form.Item>

            <Form.Item style={styles.emptyFormItem} />
          </div>

          <div className='w-full flex justify-between items-center m-1'>
            <div className='w-full flex justify-center items-center'>
              <Form.Item
                label="Break First Half:"
                 name="break_1st_start_time">{renderTimePicker("Start Time", "break_1st_start_time", 'sft_br1_start')}</Form.Item>
              <Form.Item style={styles.formItem} name="break_1st_end_time">{renderTimePicker("End Time", "break_1st_end_time", 'sft_br1_end')}</Form.Item>
            </div>
            <div className='w-full flex justify-evenly items-center'>
              <Form.Item
                label="Lunch Time:"
                style={styles.formItem} name="lunch_start_time">{renderTimePicker("Start Time", "lunch_start_time", 'sft_lunch_start')}</Form.Item>
              <Form.Item style={styles.formItem} name="lunch_end_time">{renderTimePicker("End Time", "lunch_end_time", 'sft_lunch_end')}</Form.Item>
            </div>
            <div className='w-full flex justify-evenly items-center'>
              <Form.Item
                label="Break Second Half:"
                style={styles.formItem} name="break_2nd_start_time">{renderTimePicker("Start Time", "break_2nd_start_time", 'sft_br2_start')}</Form.Item>
              <Form.Item style={styles.formItem} name="break_2nd_end_time">{renderTimePicker("End Time", "break_2nd_end_time", 'sft_br2_end')}</Form.Item>
            </div>
          </div>

          <div className='w-full relative inset-y-10'>
            <Form.Item >
              <div className={fromEdit ? 'flex justify-between m-4' : 'flex justify-end items-end m-4'} >
                {fromEdit ? (<p className='shiftNoteTxt'>{"Note:  Changed shift will reflect tomorrow"} </p>) : null}
                <div>
                  <MyButton htmlType="button" label="Cancel" onClick={(isDataAvail && !fromEdit) ? () => { setIsCancelClk(true) } : handleBackCancel} outlined={true} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID='sft_cancel' />
                  {fromEdit ? null
                    : <MyButton htmlType="button" label={"Draft"} onClick={handleSaveAsDraft} disabled={!isDataAvail} bgColor={isDataAvail ? "#334B49" : "#cbcbcb"} loading={loadings} paddingX={"0 1.2vw"} marginRight={"0.625vw"} testID='sft_draft' />}
                  <MyButton htmlType="submit" label={fromEdit ? "Update" : "Save"} loading={loadings} paddingX={"0 1.2vw"} testID='sft_draft' />
                </div>
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>
      {isCancelClk ? <CommonCancelPopup handleCancelBtn={handleCancelBtn} handleDraftBtn={handleSaveAsDraft} testID={'sft'} /> : null}
    </Card>
  );
}

const styles = {
  textField: {
    width: "23vw"
  },
  timeField: {
    width: "10.5vw"
  },
  timeBreakField: {
    width: "7vw"
  },
  formItem: {
    margin: "1vw",
  },
  emptyFormItem: {
    width: "23vw",
    margin: "1vw"
  }
}

export default AddEmployeeShift
