import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/auth/authentication";
import ToastMsg from "./ToastMsg";
import { App_Version } from "constants";

const useGetData = (apiFunction, { id } = {}) => {
  const [data, setData] = useState(null);

  // calling refetch will force a rerender on the hook
  const [shouldRefetch, setShouldFetch] = useState(1);

  const [message, setMessage] = useState({
    isErrorMsg: false,
    errorMsgText: ""
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { logoutauth } = useContext(AuthContext);

  function refetch() {
    setShouldFetch(shouldRefetch + 1);
  }

  useEffect(() => {
    async function fetchData(ids) {
      setMessage({
        isErrorMsg: false,
        errorMsgText: ""
      });
      setLoading(true);
      try {
        const apiData = await apiFunction(ids);

        if (App_Version != apiData?.version) {
          sessionStorage.clear();
          localStorage.clear();
          caches.keys().then(names => {
            for (let name of names) caches.delete(name);
          });
          window.location.reload(true);
        }

        if (apiData.detail && apiData.detail === "You are not authorized to perform this action") {
          setMessage({ isErrorMsg: true, errorMsgText: apiData.detail });
          navigate("/");
          logoutauth();
        }
      
        if(apiData.detail === "You are attempting to log in without an active subscription. Please subscribe to a plan to access the system."){
          setMessage({ isErrorMsg: true, errorMsgText: apiData.detail });
          ToastMsg("error", apiData.detail);
          navigate("/myplans");
        }
        if (apiData) {
          if (apiData.status === "fail") {
            setMessage({ isErrorMsg: true, errorMsgText: apiData.message });
          } else if (apiData.data) {
            setData(apiData.data);
            setMessage({ isErrorMsg: false, errorMsgText: apiData.message });
          }
        }
      } catch (error) {
        setMessage({ isErrorMsg: true, errorMsgText: "Error fetching data" });
      } finally {
        setLoading(false);
      }
    }
    fetchData(id);
  }, [shouldRefetch, id]);

  return [data, message, refetch, loading];
};

export default useGetData;
