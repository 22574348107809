import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/auth/authentication";
import ToastMsg from "./ToastMsg";
import { App_Version } from "constants";

const useApiData = (
  apiFunction,
  { pageNo, pageSize, query, search = "", sort = "", date }
) => {
  const [data, setData] = useState(null);
  const [extras, setExtras] = useState(null);
  const [pageInfo, setPageInfo] = useState();
  const [message, setMessage] = useState({
    isErrorMsg: false,
    errorMsgText: "",
  });
  const [loading, setLoading] = useState(false);
  const [extraDetails, setExtraDetails] = useState({});
  const navigate = useNavigate();
  const { logoutauth } = useContext(AuthContext);

  async function fetchData(pageNo, pageSize, query, search, sorting, date) {
    setMessage({
      isErrorMsg: false,
      errorMsgText: "",
    });
    setLoading(true);
    try {
      const apiData = await apiFunction({ pageNo, pageSize, query, search, sorting, date });
    
      if (App_Version != apiData?.version) {
        sessionStorage.clear();
        localStorage.clear();
        caches.keys().then(names => {
          for (let name of names) caches.delete(name);
        });
        window.location.reload(true);
      }
  
      if (apiData.detail && apiData.detail === "You are not authorized to perform this action") {
        setMessage({ isErrorMsg: true, errorMsgText: apiData.detail });
        navigate("/");
        logoutauth();
      }
      if (
        apiData.detail ===
        "You are attempting to log in without an active subscription. Please subscribe to a plan to access the system."
      ) {
        setMessage({ isErrorMsg: true, errorMsgText: apiData.detail });
        ToastMsg("error", apiData.detail);
        navigate("/myplans");
      }
      if (apiData) {
        // console.log(apiData, "apiData");

        if (apiData.status === "fail") {
          setMessage({ isErrorMsg: true, errorMsgText: apiData.message });
        } else if (apiData.data) {
          setData(apiData.data.result);
          setExtras(apiData.data.extras);
          setPageInfo({
            pageNo: apiData.data.page_no,
            pageSize: apiData.data.page_size,
            totalCount: apiData.data.total_count,
            noOfPages: apiData.data.no_of_pages,
          });
          setExtraDetails(apiData);
        }
      }
    } catch (error) {
      setMessage({ isErrorMsg: true, errorMsgText: "Error fetching data" });
      console.error("Error fetching data:", error); // Log the error for debugging
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData(pageNo, pageSize, query, search, sort, date);
  }, [pageNo, pageSize, query, search, sort, date]);

  function refetch(pageNo, pageSize, query, search, sortings, date) {
    fetchData(pageNo, pageSize, query, search, sortings, date);
  }

  return [data, pageInfo, loading, message, refetch, extraDetails, extras];
};

export default useApiData;
