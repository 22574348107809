export const statusEnum = [
    { value: "True", text: 'Active' },
    { value: "False", text: 'In Active' },
];

export const genderEnum = [
    { value: 'male', text: 'Male' },
    { value: 'female', text: 'Female' },
    { value: 'others', text: 'Others' },
];

export const titleEnum = [
    { value: 'Mr', text: 'Mr' },
    { value: 'Ms', text: 'Ms' },
    { value: 'Mrs', text: 'Mrs' },
    { value: 'Dr', text: 'Dr' },
];

export const maritalEnum = [
    { value: 'married', text: 'Married' },
    { value: 'single', text: 'Single' },
    { value: 'others', text: 'Others' },
];

export const duration = [
    { value: 'month', text: 'Month' },
    { value: 'year', text: 'Year' },
    { value: 'day', text: 'Day' },
];

export const assetType = [
    { value: 'movable', text: 'Movable' },
    { value: 'non-movable', text: 'Non-Movable' },
];

export const holidaytype = [
    { value: 'international', text: 'International' },
    { value: 'national', text: 'National' },
    { value: 'local', text: 'Local' },
    { value: 'others', text: 'Others' },
];

export const typeOfWageEnum = [
    // { value: 'hourly_wages', text: 'Hourly Wages' },
    // { value: 'daily_wages', text: 'Daily Wages' },
    // { value: 'weekly_wages', text: 'Weekly Wages' },
    { value: 'monthly_wages', text: 'Monthly Wages' },
    { value: 'no_wages', text: 'No Wages' },
];

export const modeOfPaymentEnum = [
    { value: 'online', text: 'Online' },
    { value: 'cash', text: 'Cash' },
    { value: 'nil', text: 'Nil' },
    { value: 'others', text: 'Others' },
];

export const documentType = [
    { value: 'id_proof', text: 'Id Proof' },
    { value: 'certificate', text: 'Certificate' },
    { value: 'others', text: 'Others' },
];

export const worklocationEnum = [
    { value: 'work from home', text: 'Work From Home' },
    { value: 'hybrid', text: 'Hybrid' },
    { value: 'office', text: 'Office' },
    { value: 'others', text: 'Others' },
];

export const probationPeriodEnum = [
    { value: '1_month', text: '1 Month' },
    { value: '2_months', text: '2 Months' },
    { value: '3_months', text: '3 Months' },
    { value: '4_months', text: '4 Months' },
    { value: '5_months', text: '5 Months' },
    { value: '6_months', text: '6 Months' },
    { value: '7_months', text: '7 Months' },
    { value: '8_months', text: '8 Months' },
    { value: '9_months', text: '9 Months' },
    { value: '10_months', text: '10 Months' },
    { value: '11_months', text: '11 Months' },
    { value: '12_months', text: '12 Months' },
];

export const emiEnum = [
    { value: 1, text: '1 Month' },
    { value: 2, text: '2 Months' },
    { value: 3, text: '3 Months' },
    { value: 4, text: '4 Months' },
    { value: 5, text: '5 Months' },
    { value: 6, text: '6 Months' },
    { value: 7, text: '7 Months' },
    { value: 8, text: '8 Months' },
    { value: 9, text: '9 Months' },
    { value: 10, text: '10 Months' },
    { value: 11, text: '11 Months' },
    { value: 12, text: '12 Months' },
];

export const dashboardMonthEnum = [
    { value: 1, text: 'Jan' },
    { value: 2, text: 'Feb' },
    { value: 3, text: 'Mar' },
    { value: 4, text: 'Apr' },
    { value: 5, text: 'May' },
    { value: 6, text: 'Jun' },
    { value: 7, text: 'Jul' },
    { value: 8, text: 'Aug' },
    { value: 9, text: 'Sep' },
    { value: 10, text: 'Oct' },
    { value: 11, text: 'Nov' },
    { value: 12, text: 'Dec' },
];

export const breakHrsEnum = [
    { value: "00:00:00", text: '00:00:00' },
    { value: '01:00:00', text: '01:00:00' },
    { value: '02:00:00', text: '02:00:00' },
];

export const empRemovalTypeEnum = [
    { value: "temporary", text: 'Temporary' },
    { value: 'permanent', text: 'Permanent' },
    { value: 'educational_break', text: 'Education Break' },
];

export const leaveEnum = [
    { value: "full", text: 'Full' },
    { value: 'first_half', text: 'First Half' },
    { value: 'second_half', text: 'Second Half' },
];

export const eduDesignationEnum = [
    { value: 'SSLC', text: 'SSLC' },
    { value: 'HSC', text: 'HSC' },
    { value: 'Diploma', text: 'Diplamo' },
    { value: 'PG', text: 'Post Graduate' },
    { value: 'UG', text: 'Under Graduate' },
    { value: 'Doctorate', text: 'Doctorate' },
    { value: 'others', text: 'Others' },
];

export const eduBoardEnum = [
    { value: 'state board', text: 'State Board' },
    { value: 'matriculation', text: 'Matriculation' },
    { value: 'cbse', text: 'CBSE' },
    { value: 'IGCSE', text: 'IGCSE' },
    { value: 'anna university', text: 'Anna University' },
    { value: 'bharathiyar university', text: 'Bharathiyar University' },
    { value: 'annamalai university', text: 'Annamalai University' },
];

export const eduDegreeEnum = [
    { value: 'state_board-10', text: 'State Board 10' },
    { value: 'state_board-11', text: 'State Board 11' },
    { value: 'state_board-12', text: 'State Board 12' },
    { value: 'CBSE-10', text: 'CBSE 10' },
    { value: 'CBSE-11', text: 'CBSE 11' },
    { value: 'CBSE-12', text: 'CBSE 12' },
    { value: 'Diploma', text: 'Diploma' },
    { value: 'UG', text: 'UG' },
    { value: 'PG', text: 'PG' },
    { value: 'Doctorate', text: 'Doctorate' },
    { value: 'Others', text: 'Others' },
]

export const salPercentageEnum = [
    { value: 0, text: '0%' },
    { value: 0.75, text: '0.75%' },
    { value: 1, text: '1%' },
    { value: 5, text: '5%' },
    { value: 10, text: '10%' },
    { value: 12, text: '12%' },
    { value: 15, text: '15%' },
    { value: 20, text: '20%' },
    { value: 30, text: '30%' },
    { value: 40, text: '40%' },
    { value: 50, text: '50%' },
    { value: 60, text: '60%' },
    { value: 70, text: '70%' },
    { value: 75, text: '75%' },
    { value: 80, text: '80%' },
    { value: 90, text: '90%' },
    { value: 100, text: '100%' },
];

export const relationshipEnum = [
    { value: 'mother', text: 'Mother' },
    { value: 'father', text: 'Father' },
    { value: 'spouse', text: 'Spouse' },
    { value: 'brother', text: 'Brother' },
    { value: 'sister', text: 'Sister' },
    { value: 'friend', text: 'Friend' },
    { value: 'others', text: 'Others' },
];

export const bloodGroupEnum = [
    { value: 'O-Ve', text: 'O-Ve' },
    { value: 'O+Ve', text: 'O+Ve' },
    { value: 'A+Ve', text: 'A+Ve' },
    { value: 'A-Ve', text: 'A-Ve' },
    { value: 'A1+Ve', text: 'A1+Ve' },
    { value: 'A1-Ve', text: 'A1-Ve' },
    { value: 'A1B+Ve', text: 'A1B+Ve' },
    { value: 'A1B-Ve', text: 'A1B-Ve' },
    { value: 'A2+Ve', text: 'A2+Ve' },
    { value: 'A2-Ve', text: 'A2-Ve' },
    { value: 'A2B+Ve', text: 'A2B+Ve' },
    { value: 'A2B-Ve', text: 'A2B-Ve' },
    { value: 'AB+Ve', text: 'AB+Ve' },
    { value: 'AB-Ve', text: 'AB-Ve' },
    { value: 'B+Ve', text: 'B+Ve' },
    { value: 'B-Ve', text: 'B-Ve' },
];

export const salaryShareEnum = [
    { value: "employee", text: 'Employee Share' },
    { value: 'employer', text: 'Employer Share' },
    { value: 'both', text: 'Both' },
];

export const salaryDeductEnum = [
    // { value: 'ctc', text: 'CTC' }, // as discussed
    { value: 'gross', text: 'Gross Salary' },
    //{ value: "net_salary", text: 'Net Salary' }, // as discussed
    // { value: 'basic', text: 'Basic' },
];

export const assetStatusEnum = [
    { value: "assigned", text: 'Assigned' },
    { value: 'repair', text: 'Repair' },
    { value: 'not_assigned', text: 'Not Assigned' },
    { value: 'sold_out', text: 'Sold Out' },
];

export const noticeStatusEnum = [
    { value: 1, text: 'Published' },
    { value: 2, text: 'Scheduled' },
    // { value: 3, text: 'Draft' },
];

export const leaveTypeConfigEnm = [
    { value: "leave", text: 'Leave' },
    { value: 'permission', text: 'Permission' },
    { value: 'work_from_home', text: 'Work From Home' },
];

export const selectPeriodEnm = [
    { value: 'full_day', text: 'Full Day' },
    { value: "first_half", text: 'First Half' },
    { value: 'second_half', text: 'Second Half' },
];

export const leavePolicyExpiryEnum = [
    { value: 1, text: '1 Month' },
    { value: 3, text: '3 Months' },
    { value: 6, text: '6 Months' },
    { value: 12, text: '12 Months' },
]

export const approvalTypeEnum = [
    { value: "Advance Salary", text: 'Advance Salary' },
    { value: "Reimbursements", text: 'Reimbursements' },
    { value: "Leave", text: 'Leave Request' },
    { value: "Resignation", text: 'Resignation Request' },
]

export const payrollPeriodEnum = [
    { value: 'month_wise', text: 'Month Wise' },
    { value: 'customize', text: 'Customize' },
]

export const monthEnum = [
    { value: 0, text: 'All' },
    { value: 1, text: 'January' },
    { value: 2, text: 'February' },
    { value: 3, text: 'March' },
    { value: 4, text: 'April' },
    { value: 5, text: 'May' },
    { value: 6, text: 'June' },
    { value: 7, text: 'July' },
    { value: 8, text: 'August' },
    { value: 9, text: 'September' },
    { value: 10, text: 'October' },
    { value: 11, text: 'November' },
    { value: 12, text: 'December' },
];

export const approvalStatusEnum = [
    { value: 'approved', text: 'Approved' },
    { value: 'pending', text: 'Pending' },
    { value: 'rejected', text: 'Rejected' },
]

export const NextAttemptsInEnum = [
    { value: '00:01:00', text: '1 mins' },
    { value: '00:03:00', text: '3 mins' },
    { value: '00:05:00', text: '5 mins' },
]
export const assetTypeEnum = [
    { value: "moveable", text: 'Moveable' },
    { value: 'fixed', text: 'Fixed' },
];
export const assetConditionEnum = [
    { value: "Good", text: 'Good' },
    { value: 'Bad', text: 'Bad' },
];
export const assetRegistryStatusEnum = [
    { value: "online", text: 'Online' },
    { value: 'offline', text: 'Offline' },
];

export const attendanceStatus = [
    { value: "present", text: 'Present' },
    { value: 'absent', text: 'Absent' },
    { value: 'compensation', text: 'Compensation' },
    { value: 'on-duty', text: 'On-Duty' },
    { value: 'holiday', text: 'Holiday' },
    { value: 'week-off', text: 'Week Off' },
    { value: 'WFH', text: 'Work From Home' },
]

export const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const imgUploadSize = 1; // 1MB

export const payrollStatusEnum = [
    { value: 'completed', text: 'Completed' },
    { value: 'pending', text: 'Pending' },
    { value: 'yet_to_publish', text: 'Yet To Publish' },
]

export const salaryComponentType = [
    { value: 'earning', text: 'Earnings' },
    { value: 'deduction', text: 'Deductions' },
]

export const WeekDayEnum = [
    { value: 'monday', text: 'Monday' },
    { value: 'tuesday', text: 'Tuesday' },
    { value: 'wednesday', text: 'Wednesday' },
    { value: 'thursday', text: 'Thursday' },
    { value: 'friday', text: 'Friday' },
    { value: 'saturday', text: 'Saturday' },
    { value: 'sunday', text: 'Sunday' },
]

export const rosterStatusEnum = [
    { value: "True", text: 'Published' },
    { value: "False", text: 'Saved' },
];

export const ApprovalStatus = [
    { value: "True", text: 'Approved' },
    { value: "False", text: 'Not Approved' },
];

export const ApprovedStatus = [
    { value: "approved", text: 'Approved' },
    { value: "not_approved", text: 'Not Approved' },
];

export const LetterStatus = [
    { value: "provided", text: 'Provided' },
    { value: "not_provided", text: 'Not Provided' },
];

export const SettlementStatus = [
    { value: "cleared", text: 'Cleared' },
    { value: "partially_cleared", text: 'Partially Cleared' },
    { value: "not_cleared", text: 'Not Cleared' },
    { value: "other", text: 'Other' },
];

export const default_total_earnings = {
    percentage: 0,
    monthly_amount: 0,
    annual_amount: 0,
}

export const default_total_deduction = {
    percentage: 0, // dedutions percentage of employee
    employee_monthly_amount: 0,
    employer_monthly_amount: 0,
    annual_amount: 0
}

export const verifyStatusEnum = [
    { value: 'verified', text: 'Verified' },
    { value: 'not_verified', text: 'Not Verified' },
]

export const letterStatusEnum = [
    { value: 'provided', text: 'Provided' },
    { value: 'not_provided', text: 'Not Provided' },
]
export const process_type_name = [
    { value: "on_bording", text: 'On-Boarding' },
    { value: "off_bording", text: 'Off-Boarding' },
]
