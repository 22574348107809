import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Steps } from 'antd';
import PersonalInfo from './personalInfo';
import ProfessionalInfo from './professionalInfo';
// import SalaryInfo from './salaryInfo';
import SalaryInfoBasedOnTemplate from './SalaryInfoBasedOnTemplate';
import { CreateEmployeeApi, CreateEmployeeDocuments, CreateEmployeeSalary } from './apihook/CreateEmployeeApi';
import ToastMsg from "components/common/ToastMsg";
import { getEmployeeById, getEmployeeDocumentById, getEmployeeSalaryById } from "hooks/api/employeeApi/employeeInformationApi";
import dayjs from "dayjs";
import { getSalaryTemplateList } from "hooks/api/configurationApi/payrollPeriodApi";
import { groupBy } from "components/common/CommonFuntion";
import { default_total_earnings, default_total_deduction } from "components/common/enum";
import { EmployeeDetails } from "components/common/CommonComponent";
import male from "assets/svg/Male.svg";
import EmployeeDocument from './EmployeeDocument';
import { CreateEmployeeIssuedDoc } from './apihook/EmployeeIssuedDocApi';
import { ADMIN_DOCUMENTS } from 'constants';

const default_educational_document = [
  { levels_of_education: undefined, certificate_no: "", certificate_document: [], department: "", grade: "" },
  { levels_of_education: undefined, certificate_no: "", certificate_document: [], department: "", grade: "" },
  { levels_of_education: undefined, certificate_no: "", certificate_document: [], department: "", grade: "" },
]

const default_professional_document = [
  {
    company_name: "",
    designation: "",
    start_date: "",
    end_date: "",
    experience: "",
    document_name: "",
    document: []
  }
]

const default_earnings = [
  {
    name: "basic_pay",
    monthly_amount: 0,
    percentage: 0,
    annual_amount: 0
  },
  {
    name: "hra",
    monthly_amount: 0,
    percentage: 0,
    annual_amount: 0
  }
]

const default_deductions = [
  {
    name: "pf",
    employee_share_monthly_amount: 0,
    employer_share_monthly_amount: 0,
    employee_share_percentage: 0,
    employer_share_percentage: 0,
    annual_amount: 0
  },
  {
    name: "esic",
    employee_share_monthly_amount: 0,
    employer_share_monthly_amount: 0,
    employee_share_percentage: 0,
    employer_share_percentage: 0,
    annual_amount: 0
  },
  {
    name: "tds",
    employee_share_monthly_amount: 0,
    employer_share_monthly_amount: 0,
    employee_share_percentage: 0,
    employer_share_percentage: 0,
    annual_amount: 0
  },
]

const default_asset_info = [{
  asset_registry: undefined,
  asset_name: "",
  handing_over_by: undefined,
  issued_date: null,
  remarks: ""
}]

function AddEmployeeList() {
  const navigate = useNavigate();
  const authtoken = sessionStorage.getItem("token");
  const isSuperAdmin = JSON.parse(sessionStorage.getItem("is_super_admin"));
  const isAdmin = JSON.parse(sessionStorage.getItem("is_admin"));
  const isEditAccess = (isSuperAdmin || isAdmin);

  const [personalInfo, setPersonalInfo] = useState({
    professional_info: {
      is_active: true,
      profile_document: [],
      employee_id: "",
      display_name: "",
      department: undefined,
      designation: undefined,
      reporting_manager: undefined,
      shift: undefined,
      employee_group: undefined,
      employee_type: undefined,
      work_location: "",
      date_of_joining: null,
      date_of_relieving: null,
    },
    personal_info: {
      title: undefined,
      first_name: "",
      last_name: "",
      gender: undefined,
      dob: null,
      mobile_number: "",
      email_address: "",
      permanent_address: "",
      pin_code: "",
      city: "",
      state: "",
      country: "",
      contact_person: "",
      relationship: undefined,
      contact_person_mobile_number: "",
      is_admin: false,
      blood_group: undefined,
      present_address: "",
      present_pin_code: "",
      present_city: "",
      present_state: "",
      present_country: "",
      emergency_contact_person: "",
      emergency_contact_person_relationship: "",
      emergency_contact_person_mobile_number: "",
    },
    bank_info: {
      bank_name: "",
      account_number: "",
      ifsc_code: "",
      account_holder_name: "",
      branch: "",
    },
    asset_info: default_asset_info.map(obj => ({ ...obj })),
    user_creation_info: {
      user_name: "",
      password: "",
      user_group: undefined,
      email_address: ""
    }
  });
  const [documentInfo, setDocumentInfo] = useState({
    employee_document: {
      aadhar_no: "",
      aadhar_document: [],
      esic_no: "",
      esic_document: [],
      driving_licence: "",
      driving_licence_document: [],
      passport_no: "",
      passport_document: [],
      pan: "",
      pan_document: [],
      insurance_company_name: "",
      uan: "",
      uan_document: [],
      policy_no: "",
      insurance_document: [],
    },
    educational_document: default_educational_document.map(obj => ({ ...obj })),
    professional_document: default_professional_document.map(obj => ({ ...obj })),
  })
  const [formData, setFormData] = useState({
    salary_info: {
      annual_ctc: 0,
      monthly_ctc: 0,
      // earnings: default_earnings.map(obj => ({ ...obj })),
      earnings: [],
      deductions: [],
      // deductions: default_deductions.map(obj => ({ ...obj })),
      total_earning: { ...default_total_earnings },
      total_deduction: { ...default_total_deduction },
      net_salary: 0,
      gross_salary: 0,
      total_deductions_percentage: 0,
      total_deductions_monthly_amount: 0,
      total_deductions_annual_amount: 0,
      net_salary_monthly: 0,
    }
  });
  const [empDocInfo, setEmpDocInfo] = useState({
    emp_issued_document: {
      process_type: "",
      letter_type: "",
      processed_by: "",
      approved_by: "",
      letter_provided_date: "",
      letter_status: "",
      uploaded_documents: []
    }
  })

  const location = useLocation();
  const { fromEdit, id, departmentId, designationId, designation_name, stepkey } = location.state || {};
  const [employeeId, setEmployeeId] = useState(id ? id : "");
  const [updateGet, setupdateGet] = useState(false);
  const [loading, setLoading] = useState({
    isPersonalLoad: false,
    isDocumentLoad: false,
    isSalaryLoad: false,
    isIssuedDocLoad: false
  })
  const [scrLoading, setScrLoading] = useState({
    isPersonalScrLoad: false,
    isDocumentScrLoad: false,
    isSalaryScrLoad: false,
    isIssuedDocScrLoad: false
  });
  const [dropdownList, setDropDownList] = useState({
    actualTemplateData: [],
    salaryTemplateList: {}
  });
  const [ids, setIds] = useState({
    departmentId: departmentId ? departmentId : "",
    designationId: designationId ? designationId : "",
    designation_name : designation_name ? designation_name : "",
  });
  const [currentInfoData, setCurrentInfoData] = useState({});

  useEffect(() => {
    if (!authtoken) {
      window.location.href = '/';
    }
  }, [authtoken]);

  const getSalaryTemplate = async () => {
    try {
      const apiData = await getSalaryTemplateList(`drop_down=True`);
      if (apiData && apiData?.status === "success" && apiData?.data) {
        setDropDownList((prevFilterData) => ({
          ...prevFilterData,
          actualTemplateData: [...apiData?.data]
        }))
      } else {
        setDropDownList((prevFilterData) => ({
          ...prevFilterData,
          actualTemplateData: []
        }))
      }
    } catch (error) {
      setDropDownList((prevFilterData) => ({
        ...prevFilterData,
        actualTemplateData: []
      }))
    }
  }

  useEffect(() => {
    getSalaryTemplate();
  }, []);

  const getEmployeePersonalInfo = async (id) => {
    setScrLoading((prevState) => ({
      ...prevState,
      isPersonalScrLoad: true
    }))
    try {
      const apiData = await getEmployeeById(id);
      if (apiData && apiData?.status === "success" && apiData?.data && apiData?.data?.result?.length > 0) {
        assignPersonalInfo({ ...apiData.data.result[0] });
      }
    } catch (error) {
      console.error(error)
    } finally {
      setScrLoading((prevState) => ({
        ...prevState,
        isPersonalScrLoad: false
      }))
    }
  };

  const getEmployeeDocumentInfo = async (id) => {
    setScrLoading((prevState) => ({
      ...prevState,
      isDocumentScrLoad: true
    }))
    try {
      if (id) {
        const apiData = await getEmployeeDocumentById(id);
        if (apiData && apiData.status === "success" && apiData.data && apiData.data.result.length > 0) {
          assignDocuments({ ...apiData.data.result[0] });
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setScrLoading((prevState) => ({
        ...prevState,
        isDocumentScrLoad: false
      }))
    }
  };

  const getEmployeeSalaryInfo = async (id) => {
    setScrLoading((prevState) => ({
      ...prevState,
      isSalaryScrLoad: true
    }))
    try {
      if (id) {
        const apiData = await getEmployeeSalaryById(id);
        if (apiData && apiData.status === "success" && apiData.data && apiData.data.result.length > 0 && (apiData.data.result[0].gross_salary_monthly_amount)) {
          assignSalaryInfo({ ...apiData.data.result[0] })
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setScrLoading((prevState) => ({
        ...prevState,
        isSalaryScrLoad: false
      }))
    }
  }

  useEffect(() => {
    if (employeeId && fromEdit) {
      getEmployeePersonalInfo(employeeId);
      switch (stepkey) {
        case 1:
          setCurrent(stepkey)
          getEmployeeDocumentInfo(employeeId);
          break;
        case 2:
          setCurrent(stepkey)
          getEmployeeSalaryInfo(employeeId);
          break;
        case 3:
          setCurrent(stepkey)
      }
    }
  }, [employeeId])

  const assignPersonalInfo = (datas) => {
    if (datas && Object.keys(datas).length > 0) {
      setPersonalInfo((prevData) => ({
        ...prevData,
        professional_info: {
          is_active: datas?.professional_info?.is_active,
          profile_document: datas?.professional_info?.profile ? datas?.professional_info?.profile : [],
          employee_id: datas?.professional_info?.employee_id ? datas?.professional_info?.employee_id : "",
          display_name: datas?.professional_info?.display_name ? datas?.professional_info?.display_name : "",
          department: datas?.professional_info?.department ? datas?.professional_info?.department : undefined,
          designation: datas?.professional_info?.designation ? datas?.professional_info?.designation : undefined,
          reporting_manager: datas?.professional_info?.reporting_manager ? datas?.professional_info?.reporting_manager : undefined,
          shift: datas?.professional_info?.shift ? datas?.professional_info?.shift : undefined,
          employee_group: datas?.professional_info?.employee_group ? datas?.professional_info?.employee_group : undefined,
          work_location: datas?.professional_info?.work_location ? datas?.professional_info?.work_location : "",
          employee_type: datas?.professional_info?.employee_type ? datas?.professional_info?.employee_type : undefined,
          date_of_joining: datas.professional_info.date_of_joining ? dayjs(datas?.professional_info?.date_of_joining, "YYYY-MM-DD") : null,
          date_of_relieving: datas.professional_info.date_of_releving ? dayjs(datas.professional_info.date_of_releving, "YYYY-MM-DD") : null,
        },
        personal_info: {
          title: datas?.personal_info?.title ? datas?.personal_info?.title : undefined,
          first_name: datas?.personal_info?.first_name ? datas?.personal_info?.first_name : "",
          last_name: datas?.personal_info?.last_name ? datas?.personal_info?.last_name : "",
          gender: datas?.personal_info?.gender ? datas?.personal_info?.gender : undefined,
          dob: datas?.personal_info?.date_of_birth ? dayjs(datas?.personal_info?.date_of_birth, "YYYY-MM-DD") : null,
          mobile_number: datas?.personal_info?.mobile_number ? datas?.personal_info?.mobile_number : "",
          email_address: datas?.personal_info?.email_address ? datas?.personal_info?.email_address : "",
          permanent_address: datas?.personal_info?.permanent_address ? datas?.personal_info?.permanent_address : "",
          pin_code: datas?.personal_info?.pin_code ? datas?.personal_info?.pin_code : "",
          city: datas?.personal_info?.city ? datas?.personal_info?.city : "",
          state: datas?.personal_info?.state ? datas?.personal_info?.state : "",
          country: datas?.personal_info?.country ? datas?.personal_info?.country : "",
          contact_person: datas?.personal_info?.contact_person ? datas?.personal_info?.contact_person : "",
          relationship: datas?.personal_info?.relationship ? datas?.personal_info?.relationship : undefined,
          contact_person_mobile_number: datas?.personal_info?.contact_person_mobile_number ? datas?.personal_info?.contact_person_mobile_number : "",
          is_admin: datas?.personal_info?.is_admin ? datas?.personal_info?.is_admin : false,
          blood_group: datas?.personal_info?.blood_group ? datas?.personal_info?.blood_group : undefined,
          present_address: datas?.personal_info?.present_address ? datas?.personal_info?.present_address : "",
          present_pin_code: datas?.personal_info?.present_pin_code ? datas?.personal_info?.present_pin_code : "",
          present_city: datas?.personal_info?.present_city ? datas?.personal_info?.present_city : "",
          present_state: datas?.personal_info?.present_state ? datas?.personal_info?.present_state : "",
          present_country: datas?.personal_info?.present_country ? datas?.personal_info?.present_country : "",
          emergency_contact_person: datas?.personal_info?.emergency_contact_person ? datas?.personal_info?.emergency_contact_person : "",
          emergency_contact_person_relationship: datas?.personal_info?.emergency_contact_person_relationship ? datas?.personal_info?.emergency_contact_person_relationship : "",
          emergency_contact_person_mobile_number: datas?.personal_info?.emergency_contact_person_mobile_number ? datas?.personal_info?.emergency_contact_person_mobile_number : "",
        },
        bank_info: {
          id: datas?.bank_info?.id ? datas?.bank_info?.id : "",
          bank_name: datas?.bank_info?.bank_name ? datas?.bank_info?.bank_name : "",
          account_number: datas?.bank_info?.account_number ? datas?.bank_info?.account_number : "",
          ifsc_code: datas?.bank_info?.ifsc_code ? datas?.bank_info?.ifsc_code : "",
          account_holder_name: datas?.bank_info?.account_holder_name ? datas?.bank_info?.account_holder_name : "",
          branch: datas?.bank_info?.branch ? datas?.bank_info?.branch : "",
        },
        asset_info: datas?.asset_info?.length > 0 ? datas.asset_info.map(item => ({
          ...item,
          issued_date: item?.issued_date ? dayjs(item?.issued_date, "YYYY-MM-DD") : undefined,
        })) : default_asset_info.map(obj => ({ ...obj })),
        user_creation_info: {
          id: datas?.user_creation_info?.user_id ? datas?.user_creation_info?.user_id : "",
          user_name: datas?.user_creation_info?.user_name ? datas?.user_creation_info?.user_name : "",
          password: datas?.user_creation_info?.password ? datas?.user_creation_info?.password : "",
          user_group: datas?.user_creation_info?.user_group_id ? datas?.user_creation_info?.user_group_id : null,
          email_address: datas?.user_creation_info?.profesional_mail ? datas?.user_creation_info?.profesional_mail : ""
        }
      }));

      setEmployeeDetails((prevState) => ({
        ...prevState,
        title: datas?.personal_info?.title ? datas?.personal_info?.title : "",
        first_name: datas?.personal_info?.first_name ? datas?.personal_info?.first_name : "",
        last_name: datas?.personal_info?.last_name ? datas?.personal_info?.last_name : "",
        designation_id: {
          id: ids?.designationId ? ids?.designationId : "",
          designation_name: ids?.designation_name ? ids?.designation_name : ""
        },
        id: datas?.professional_info?.employee_id,
        profile: datas?.professional_info?.profile_document ? datas?.professional_info?.profile_document : prevState?.profile ? prevState?.profile : male,
      }))
    }
  }


  const assignDocuments = (datas) => {
    if (datas && Object.keys(datas).length > 0) {

      if (datas.professional_document?.length > 0) {
        datas.professional_document?.forEach(item => {
          if (item.start_date || item.end_date) {
            if (item.start_date) {
              item.start_date = dayjs(item.start_date, "YYYY-MM-DD")
            }
            if (item.end_date) {
              item.end_date = dayjs(item.end_date, "YYYY-MM-DD")
            }
          }
        })
      }

      setDocumentInfo((prevFormData) => ({
        ...prevFormData,
        employee_document: {
          ...prevFormData.employee_document,
          id: datas?.employee_document?.id,
          employee: datas?.employee_document?.employee,
          aadhar_no: datas?.employee_document?.aadhar_no ? datas?.employee_document?.aadhar_no : "",
          aadhar_document: datas?.employee_document?.aadhar_document ? datas?.employee_document?.aadhar_document : [],
          esic_no: datas?.employee_document?.esic_no ? datas?.employee_document?.esic_no : "",
          esic_document: datas?.employee_document?.esic_document ? datas?.employee_document?.esic_document : [],
          driving_licence: datas?.employee_document?.driving_licence ? datas?.employee_document?.driving_licence : "",
          driving_licence_document: datas?.employee_document?.driving_licence_document ? datas?.employee_document?.driving_licence_document : [],
          passport_no: datas?.employee_document?.passport_no ? datas?.employee_document?.passport_no : "",
          passport_document: datas?.employee_document?.passport_document ? datas?.employee_document?.passport_document : [],
          pan: datas?.employee_document?.pan ? datas?.employee_document?.pan : "",
          pan_document: datas?.employee_document?.pan_document ? datas?.employee_document?.pan_document : [],
          insurance_company_name: datas?.employee_document?.insurance_company_name ? datas?.employee_document?.insurance_company_name : "",
          uan: datas?.employee_document?.uan ? datas?.employee_document?.uan : "",
          uan_document: datas?.employee_document?.uan_document || [],
          policy_no: datas?.employee_document?.policy_no ? datas?.employee_document?.policy_no : "",
          insurance_document: datas?.employee_document?.insurance_document ? datas?.employee_document?.insurance_document : [],
        },
        educational_document: datas?.educational_document?.length > 0 ?
          [...datas?.educational_document, { levels_of_education: "", certificate_no: "", certificate_document: [], department: "", grade: "" },]
          : default_educational_document.map(obj => ({ ...obj })),
        professional_document: datas?.professional_document?.length > 0 ?
          [...datas?.professional_document] : default_professional_document.map(obj => ({ ...obj })),
      }));
    }
  }

  const assignSalaryInfo = (datas) => {
    if (datas && Object.keys(datas).length > 0) {
      let edit_total_earning = datas?.earnings ? datas?.earnings?.reduce((acc, cur) => {
        acc["percentage"] += parseFloat(cur.percentage);
        acc["monthly_amount"] += parseFloat(cur.monthly_amount);
        acc["annual_amount"] += parseFloat(cur.annual_amount);
        return acc;
      }, { ...default_total_earnings }) : {};

      let edit_total_dedutions = datas?.deductions ? datas?.deductions?.reduce((acc, cur) => {
        acc["percentage"] = datas?.total_deductions_percentage || 0;
        acc["employee_monthly_amount"] += parseFloat(cur.employee_share_monthly_amount);
        acc["employer_monthly_amount"] += parseFloat(cur.employer_share_monthly_amount);
        acc["annual_amount"] = datas?.total_deductions_annual_amount || 0;
        return acc;
      }, { ...default_total_deduction }) : {};

      setFormData((prevFormData) => ({
        ...prevFormData,
        salary_info: {
          ...prevFormData.salary_info,
          salary_id: datas?.id ? datas?.id : "",
          is_active: datas?.is_active ? datas?.is_active : true,
          annual_ctc: datas?.annual_ctc ? datas?.annual_ctc : 0,
          monthly_ctc: datas?.monthly_ctc ? datas?.monthly_ctc : 0,
          // earnings: datas?.earnings?.length > 0 ? datas?.earnings : [...default_earnings],
          // deductions: datas?.deductions?.length > 0 ? datas?.deductions : [...default_deductions],
          earnings: datas?.earnings?.length > 0 ? datas?.earnings : [],
          deductions: datas?.deductions?.length > 0 ? datas?.deductions : [],
          total_earning: Object.keys(edit_total_earning).length > 0 ? { ...edit_total_earning } : { ...default_total_earnings },
          total_deduction: Object.keys(edit_total_dedutions).length > 0 ? { ...edit_total_dedutions } : { ...default_total_deduction },
          net_salary: datas?.net_salary ? datas?.net_salary : 0,
          gross_salary: datas?.gross_salary_monthly_amount ? datas?.gross_salary_monthly_amount : 0,
          total_deductions_percentage: datas?.total_deductions_percentage ? datas?.total_deductions_percentage : 0,
          total_deductions_monthly_amount: datas?.total_deductions_monthly_amount ? datas?.total_deductions_monthly_amount : 0,
          total_deductions_annual_amount: datas?.total_deductions_annual_amount ? datas?.total_deductions_annual_amount : 0,
          net_salary_monthly: parseFloat(datas?.net_salary || 0) / 12,
          salary_template_id: datas?.template || "",
          gross_monthly_amount: datas?.gross_salary_monthly_amount || 0,
          gross_annual_amount: parseFloat(datas?.gross_salary_monthly_amount || 0) * 12,
        }
      }));
    }
  }

  const updatePersonalData = (statekey, name, value) => {
    setPersonalInfo((prevData) => ({
      ...prevData,
      [statekey]: {
        ...prevData[statekey],
        [name]: value,
      },
    }));
  }

  const updateDocumentData = (statekey, name, value) => {
    setDocumentInfo((prevData) => ({
      ...prevData,
      [statekey]: {
        ...prevData[statekey],
        [name]: value,
      },
    }));
  }

  const updateEmpDocData = (statekey, name, value) => {
    setEmpDocInfo((prevData) => ({
      ...prevData,
      [statekey]: {
        ...prevData[statekey],
        [name]: value,
      },
    }));
  }

  const updateWholePersonalData = (statekey, value) => {
    setPersonalInfo((prevData) => ({
      ...prevData,
      [statekey]: value,
    }));
  }

  const updateWholeDocumentData = (statekey, value) => {
    setDocumentInfo((prevData) => ({
      ...prevData,
      [statekey]: value,
    }));
  }

  const updateWholeFormData = (statekey, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [statekey]: value,
    }));
  }

  const updateWholeEmpDocData = (statekey, value) => {
    setEmpDocInfo((prevData) => ({
      ...prevData,
      [statekey]: value,
    }));
  }

  const handleFinish = () => {
    setEmployeeId("");
    navigate("/employee/employees");
  }

  const filterAndGroupData = (data, designationId = null, departmentId = null) => {
    const filteredData = data?.filter(item =>
      (designationId === null || item?.designation?.id === designationId || !item?.designation?.id) &&
      (departmentId === null || item?.department?.id === departmentId) && item?.is_active
    );
    return groupBy(filteredData, (data) => {
      const departmentName = data?.department?.department_name ?? 'N/A';
      const designationName = data?.designation?.designation_name ?? 'N/A';
      return `${departmentName} - ${designationName}`;
    });
  }

  const handleFieldDeletion = (info, field) => {
    for (let key in info[field]) {
      if (key === "is_active" || key === "is_admin") {
        continue;
      }
      if (Array.isArray(info[field][key]) && info[field][key].length === 0) {
        delete info[field][key];
      } else if (!info[field][key] || info[field][key] === "Invalid Date") {
        delete info[field][key];
      }
    }
  };

  const removeFileExtension = (filename) => {
    return filename.split('.').slice(0, -1).join('.');
  }

  const isValidDate = (dateString) => {
    const dateObject = new Date(dateString);
    return !isNaN(dateObject.getTime());
  }

  useEffect(() => {
    if (stepkey === 2) {
      fetchSalaryTemplates();
    }
  }, [stepkey]);

  const fetchSalaryTemplates = async () => {
    try {
      const apiData = await getSalaryTemplateList(`drop_down=True`);
      // console.log(apiData, "apiData");

      if (apiData && apiData.status === "success" && apiData.data) {
        // Update actualTemplateData first
        setDropDownList((prevState) => ({
          ...prevState,
          actualTemplateData: apiData.data,
        }));

        // Filter data based on `designationId`, `departmentId`, and `designation_name`
        const filteredData = apiData.data.filter(item =>
          (designationId === null || item?.designation?.id === designationId || !item?.designation?.id) &&
          (departmentId === null || item?.department?.id === departmentId) ||
          (designation_name === null || item?.designation?.designation_name === designation_name)
        );

        // Group templates after filtering
        const templateList = groupBy(filteredData, (data) => {
          const departmentName = data?.department?.department_name ?? 'N/A';
          const designationName = data?.designation?.designation_name ?? 'N/A';
          return `${departmentName} - ${designationName}`;
        });

        // Update salaryTemplateList if matches are found
        setDropDownList((prevState) => ({
          ...prevState,
          salaryTemplateList: Object.keys(templateList).length > 0 ? templateList : {}
        }));
      }
    } catch (error) {
      console.error("Failed to fetch salary templates:", error);
    }
  };

  const handlePersonalSave = (stepper) => {
    const modifiedInfo = { ...personalInfo };
    setCurrentInfoData({ ...personalInfo });
    // handleFieldDeletion(modifiedInfo, "professional_info");
    // handleFieldDeletion(modifiedInfo, "personal_info");
    // handleFieldDeletion(modifiedInfo, "bank_info");
    modifiedInfo.asset_info = modifiedInfo.asset_info?.filter(row =>
      row.asset_registry || row.asset_name || row.handing_over_by || (row.issued_date && isValidDate(row.issued_date)));
    // creating keys for put and post
    if (modifiedInfo.asset_info?.length > 0) {
      modifiedInfo.asset_info?.forEach(item => {
        if (item.issued_date) {
          item.issued_date = dayjs(item.issued_date).format("YYYY-MM-DD")
        }
      })
    }
    // handleFieldDeletion(modifiedInfo, "user_creation_info");

    if (modifiedInfo.professional_info.date_of_joining) {
      modifiedInfo.professional_info.date_of_joining = dayjs(modifiedInfo?.professional_info?.date_of_joining).format("YYYY-MM-DD")
    }

    if (modifiedInfo.professional_info.date_of_relieving) {
      modifiedInfo.professional_info.date_of_relieving = dayjs(modifiedInfo?.professional_info?.date_of_relieving).format("YYYY-MM-DD")
    }

    modifiedInfo.personal_info.pin_code = modifiedInfo.personal_info.pin_code ? modifiedInfo.personal_info.pin_code : null;
    modifiedInfo.personal_info.present_pin_code = modifiedInfo.personal_info.present_pin_code ? modifiedInfo.personal_info.present_pin_code : null;
    if (modifiedInfo.personal_info.dob) {
      modifiedInfo.personal_info.dob = dayjs(modifiedInfo?.personal_info?.dob).format("YYYY-MM-DD")
    }

    if (modifiedInfo.professional_info.profile_document && modifiedInfo.professional_info.profile_document.length > 0 &&
      Object.keys(modifiedInfo.professional_info.profile_document[0])?.length > 0) {
      let imgObj = { ...modifiedInfo.professional_info.profile_document[0] };
      if (imgObj.hasOwnProperty('name')) {
        modifiedInfo["professional_info"]["profile"] = removeFileExtension(imgObj.name);
        modifiedInfo["professional_info__profile"] = imgObj ? imgObj.originFileObj : [];
        delete modifiedInfo.professional_info.profile_document;
      }
    }
    setLoading((prevState) => ({
      ...prevState,
      isPersonalLoad: true
    }));
    CreateEmployeeApi(
      modifiedInfo,
      authtoken,
      fromEdit,
      employeeId,
      async (data) => {
        //onSuccess
        if (isEditAccess) {
          ToastMsg("success", data.message);
        }
        setEmployeeId(data.employee_id);
        setIds((prevState) => ({
          ...prevState,
          departmentId: data?.department,
          designationId: data?.designation,
          designation_name: data?.designation_name ? data?.designation_name : prevState?.designation_name
        }));
        setDropDownList((prevState) => {
          let templateList = filterAndGroupData(prevState?.actualTemplateData, data?.designation, data?.department)
          return {
            ...prevState,
            salaryTemplateList: Object.keys(templateList)?.length > 0 ? templateList : {}
          }
        })

        setEmployeeDetails((prevState) => ({
          ...prevState,
          title: personalInfo?.personal_info?.title ? personalInfo?.personal_info?.title : "",
          first_name: personalInfo?.personal_info?.first_name ? personalInfo?.personal_info?.first_name : "",
          last_name: personalInfo?.personal_info?.last_name ? personalInfo?.personal_info?.last_name : "",
          designation_id: {
            id: ids?.designationId ? ids?.designationId : "",
            designation_name: ids?.designation_name ? ids?.designation_name : ""
          },
          id: personalInfo?.professional_info?.employee_id,
          profile: data?.profile ? data?.profile : prevState.profile ? prevState.profile : male,
        }))

        setLoading((prevState) => ({
          ...prevState,
          isPersonalLoad: false
        }));
        if (fromEdit) {
          getEmployeeDocumentInfo(employeeId);
        }
        setPersonalInfo({
          professional_info: {
            is_active: true,
            profile_document: [],
            employee_id: "",
            display_name: "",
            department: "",
            designation: "",
            reporting_manager: "",
            shift: "",
            employee_group: "",
            employee_type: "",
            work_location: "",
            date_of_joining: null,
            date_of_relieving: null,
          },
          personal_info: {
            title: "",
            first_name: "",
            last_name: "",
            gender: "",
            dob: null,
            mobile_number: "",
            email_address: "",
            permanent_address: "",
            pin_code: "",
            city: "",
            state: "",
            country: "",
            contact_person: "",
            relationship: "",
            contact_person_mobile_number: "",
            is_admin: false,
            blood_group: "",
            present_address: "",
            present_pin_code: "",
            present_city: "",
            present_state: "",
            present_country: "",
            emergency_contact_person: "",
            emergency_contact_person_relationship: "",
            emergency_contact_person_mobile_number: "",
          },
          bank_info: {
            bank_name: "",
            account_number: "",
            ifsc_code: "",
            account_holder_name: "",
            branch: "",
          },
          asset_info: [...default_asset_info],
          user_creation_info: {
            user_name: "",
            password: "",
            user_group: null,
            email_address: ""
          }
        })
        if(!stepper){
          setCurrent(1);
        }
        window.scrollTo(0, 0)
      },
      (error) => {
        //onError
        setLoading((prevState) => ({
          ...prevState,
          isPersonalLoad: false
        }));
        if (isEditAccess) {
          ToastMsg("error", error);
        }
      }
    )
  }

  useEffect(() => {
    if (personalInfo?.personal_info?.first_name && stepkey == 2 && current == 2) {
      handlePersonalSave(stepkey)
    }
  }, [personalInfo?.personal_info?.first_name, stepkey])

  const handleProfSave = () => {
    const modifiedProfInfo = {
      employee_document: { ...documentInfo.employee_document },
      educational_document: documentInfo.educational_document.map(obj => ({ ...obj })),
      professional_document: documentInfo.professional_document.map(obj => ({ ...obj })),
    };

    // if(!modifiedProfInfo.employee_document?.id){
    //   handleFieldDeletion(modifiedProfInfo, "employee_document");
    // }

    if (Object.keys(modifiedProfInfo.employee_document).length > 0) {
      for (var key in modifiedProfInfo.employee_document) {
        if (key.includes("document")) {
          if (modifiedProfInfo["employee_document"][key] && modifiedProfInfo["employee_document"][key].length > 0 && Object.keys(modifiedProfInfo["employee_document"][key][0]).length > 0) {
            let imgObj = { ...modifiedProfInfo["employee_document"][key][0] };
            if (imgObj.hasOwnProperty('name')) {
              modifiedProfInfo["employee_document"][key] = imgObj.name;
              modifiedProfInfo[`employee_document__${key}`] = imgObj.originFileObj;
            }
          } else {
            modifiedProfInfo["employee_document"][key] = null
          }
        }
      }
    }

    modifiedProfInfo.educational_document = modifiedProfInfo.educational_document?.filter(row =>
      row.levels_of_education || row.certificate_no ||
      ((row.certificate_document && typeof row.certificate_document === "string")
        || (row.certificate_document && Array.isArray(row.certificate_document) && row.certificate_document.length > 0))
      || row.department || row.grade
    );

    // creating keys for put and post
    if (modifiedProfInfo.educational_document?.length > 0) {
      modifiedProfInfo.educational_document?.forEach(item => {
        if (item.certificate_document && item.certificate_document?.length > 0 && Object.keys(item.certificate_document[0]).length > 0) {
          let imgObj = { ...item.certificate_document[0] };
          if (imgObj.hasOwnProperty('name')) {
            let filename = removeFileExtension(imgObj.name);
            item["certificate_document"] = imgObj.name;
            modifiedProfInfo[`educational_document__${filename}`] = imgObj.originFileObj;
          }
        } else {
          item["certificate_document"] = null;
        }
      })
    }

    modifiedProfInfo.professional_document = modifiedProfInfo.professional_document?.filter(row =>
    (row.company_name || row.designation || (row.start_date && isValidDate(row.start_date)) ||
      (row.end_date && isValidDate(row.end_date)) || row.experience || row.document_name ||
      ((row.document && typeof row.document === "string") ||
        (row.document && Array.isArray(row.document) && row.document.length > 0)))
    )

    // creating keys for put and post
    if (modifiedProfInfo.professional_document?.length > 0) {
      modifiedProfInfo.professional_document?.forEach(item => {
        if (item.start_date || item.end_date) {
          if (item.start_date) {
            item.start_date = dayjs(item.start_date).format("YYYY-MM-DD")
          }

          if (item.end_date) {
            item.end_date = dayjs(item.end_date).format("YYYY-MM-DD")
          }
        }
        if (item.document && item.document.length > 0 && Object.keys(item.document[0]).length > 0) {
          let imgObj = { ...item.document[0] };
          if (imgObj.hasOwnProperty('name')) {
            let filename = removeFileExtension(imgObj.name);
            item["document"] = imgObj.name;
            modifiedProfInfo[`professional_document__${filename}`] = imgObj.originFileObj;
          }
        } else {
          item.document = null;
        }
      })
    }
    // if (Object.keys(modifiedProfInfo.employee_document).length > 0 ||
    //   modifiedProfInfo.professional_document?.length > 0 ||
    //   modifiedProfInfo.educational_document?.length > 0) {
    setLoading((prevState) => ({
      ...prevState,
      isDocumentLoad: true
    }));
    CreateEmployeeDocuments(
      modifiedProfInfo,
      authtoken,
      fromEdit,
      employeeId,
      async (data) => {
        //onSuccess
        if (isEditAccess) {
          ToastMsg("success", data.message);
        }
        if (fromEdit) {
          getEmployeeSalaryInfo(data.employee_id);
        }
        setEmployeeId(data.employee_id);
        setLoading((prevState) => ({
          ...prevState,
          isDocumentLoad: false
        }));
        setDocumentInfo({
          employee_document: {
            aadhar_no: "",
            aadhar_document: [],
            esic_no: "",
            esic_document: [],
            driving_licence: "",
            driving_licence_document: [],
            passport_no: "",
            passport_document: [],
            pan: "",
            pan_document: [],
            insurance_company_name: "",
            uan: "",
            uan_document: [],
            policy_no: "",
            insurance_document: [],
          },
          educational_document: [...default_educational_document],
          professional_document: [...default_professional_document],
        })
        setCurrent(2);
        window.scrollTo(0, 0)
      },
      (error) => {
        //onError
        setLoading((prevState) => ({
          ...prevState,
          isDocumentLoad: false
        }));
        if (isEditAccess) {
          ToastMsg("error", error);
        }
      }
    )
    // } else {
    //   if (fromEdit) {
    //     getEmployeeSalaryInfo(employeeId);
    //   }
    //   setCurrent(2);
    //   window.scrollTo(0, 0)
    // }
  }

  const handleSalarySave = (values) => {
    let updateFormData = { ...values };
    updateFormData["employee_id"] = employeeId;
    updateFormData["salary_id"] = formData?.salary_info?.salary_id ? formData?.salary_info?.salary_id : null;
    updateFormData["is_active"] = formData?.salary_info?.is_active ? formData?.salary_info?.is_active : true;
    updateFormData["gross_salary_percentage"] = updateFormData?.total_earning?.percentage ? updateFormData?.total_earning?.percentage : 0;
    updateFormData["gross_salary_monthly_amount"] = updateFormData?.total_earning?.monthly_amount ? Number(updateFormData?.total_earning?.monthly_amount) : 0;
    updateFormData["gross_salary_annual_amount"] = updateFormData?.total_earning?.annual_amount ? updateFormData?.total_earning?.annual_amount : 0;
    updateFormData["template"] = updateFormData?.salary_template_id ? updateFormData?.salary_template_id : "";
    updateFormData["total_deductions_percentage"] = updateFormData?.total_deduction?.percentage ? updateFormData?.total_deduction?.percentage : 0;
    updateFormData["total_deductions_monthly_amount"] = updateFormData?.total_deduction?.annual_amount ? parseFloat(updateFormData?.total_deduction?.annual_amount / 12) : 0;
    updateFormData["total_deductions_annual_amount"] = updateFormData?.total_deduction?.annual_amount ? updateFormData?.total_deduction?.annual_amount : 0;
    delete updateFormData.total_earning;
    delete updateFormData.total_deduction;
    delete updateFormData.gross_salary;
    delete updateFormData.gross_monthly_amount;
    delete updateFormData.gross_annual_amount;
    delete updateFormData.salary_template_id;
    setLoading((prevState) => ({
      ...prevState,
      isSalaryLoad: true
    }));
    CreateEmployeeSalary(
      updateFormData,
      authtoken,
      fromEdit,
      async (data) => {
        //onSuccess
        // setEmployeeId("");
        setLoading((prevState) => ({
          ...prevState,
          isSalaryLoad: false
        }));
        // navigate("/employee/employees");
        setCurrent(3);
        if (isEditAccess) {
          ToastMsg("success", data.message);
        }
      },
      (error) => {
        //onError
        setLoading((prevState) => ({
          ...prevState,
          isSalaryLoad: false
        }));
        if (isEditAccess) {
          ToastMsg("error", error);
        }
      }
    )
  }

  const handleIssuedDocSave = (values) => {
    let updateFormData = { ...values };
    updateFormData["employee_id"] = employeeId;
    updateFormData["letter_type"] = empDocInfo?.emp_issued_document?.letter_type ? empDocInfo?.emp_issued_document?.letter_type : null;
    updateFormData["processed_by"] = empDocInfo?.emp_issued_document?.processed_by ? empDocInfo?.emp_issued_document?.processed_by : null;
    updateFormData["approved_by"] = empDocInfo?.emp_issued_document?.approved_by ? empDocInfo?.emp_issued_document?.approved_by : null;
    updateFormData["letter_provided_date"] = empDocInfo?.emp_issued_document?.letter_provided_date ? dayjs(empDocInfo?.emp_issued_document?.letter_provided_date).format("YYYY-MM-DD") : null;
    updateFormData["letter_status"] = empDocInfo?.emp_issued_document?.letter_status ? empDocInfo?.emp_issued_document?.letter_status : null;
    updateFormData["uploaded_documents"] = empDocInfo?.emp_issued_document?.uploaded_documents?.length > 0 ? empDocInfo?.emp_issued_document?.uploaded_documents.map(imgObj => (imgObj)) : [];

    setLoading((prevState) => ({
      ...prevState,
      isIssuedDocLoad: true
    }));
    CreateEmployeeIssuedDoc(
      updateFormData,
      authtoken,
      fromEdit,
      async (data) => {
        //onSuccess
        setLoading((prevState) => ({
          ...prevState,
          isIssuedDocLoad: false
        }));
        setEmpDocInfo({
          emp_issued_document: {
            process_type: "",
            letter_type: "",
            processed_by: "",
            approved_by: "",
            letter_provided_date: "",
            letter_status: "",
            uploaded_documents: []
          }
        })
        setupdateGet((prevState) => !prevState);
        setCurrent(3);
        if (isEditAccess) {
          ToastMsg("success", data.message);
        }
      },
      (error) => {
        //onError
        setLoading((prevState) => ({
          ...prevState,
          isIssuedDocLoad: false
        }));
        if (isEditAccess) {
          ToastMsg("error", error);
        }
      }
    )
  }

  const prev = (backFrom) => {
    if (backFrom === "fromProfessional" && employeeId) {
      getEmployeePersonalInfo(employeeId);
    }
    if (backFrom === "fromSalary" && employeeId) {
      getEmployeeDocumentInfo(employeeId);
    }
    if (backFrom === "fromIssuedDoc" && employeeId) {
      getEmployeeSalaryInfo(employeeId)
    }
    setCurrent(current - 1);
    window.scrollTo(0, 0)
  };

  const goBack = () => {
    navigate("/employee/employees");
    setPersonalInfo({
      professional_info: {
        is_active: true,
        profile_document: [],
        employee_id: "",
        display_name: "",
        department: undefined,
        designation: undefined,
        reporting_manager: undefined,
        shift: undefined,
        employee_group: undefined,
        employee_type: undefined,
        work_location: "",
        date_of_joining: null,
        date_of_relieving: null,
      },
      personal_info: {
        title: undefined,
        first_name: "",
        last_name: "",
        gender: undefined,
        dob: null,
        mobile_number: "",
        email_address: "",
        permanent_address: "",
        pin_code: "",
        city: "",
        state: "",
        country: "",
        contact_person: "",
        relationship: undefined,
        contact_person_mobile_number: "",
      },
      bank_info: {
        bank_name: "",
        account_number: "",
        ifsc_code: "",
        account_holder_name: "",
        branch: "",
      },
      asset_info: [...default_asset_info],
      user_creation_info: {
        user_name: "",
        password: "",
        user_group: undefined,
        email_address: ""
      }
    })
  }

  const steps = [
    {
      title: 'Personal Info',
      content: <PersonalInfo formData={personalInfo} updateFormData={updatePersonalData} onSave={handlePersonalSave}
        onBack={goBack} updateWholeFormData={updateWholePersonalData} isEdit={fromEdit} employeeId={employeeId}
        isLoading={loading.isPersonalLoad} isScrLoading={scrLoading.isPersonalScrLoad}
      />,
    },
    {
      title: 'Documents',
      content: <ProfessionalInfo formData={documentInfo} updateFormData={updateDocumentData}
        updateWholeFormData={updateWholeDocumentData} onSave={handleProfSave} OnBack={prev} isLoading={loading.isDocumentLoad}
        isScrLoading={scrLoading.isDocumentScrLoad} />,
    },
    // {
    //   title: 'Salary Info',
    //   content: <SalaryInfo formData={formData} updateWholeFormData={updateWholeFormData}
    //   OnBack={prev} onSave={handleSalarySave}  fromEdit = {fromEdit} isLoading = {loading.isSalaryLoad}
    //   isScrLoading = {scrLoading.isSalaryScrLoad}/>,
    // },
    {
      title: 'Salary Info',
      content: <SalaryInfoBasedOnTemplate formData={formData?.salary_info} updateWholeFormData={updateWholeFormData}
        OnBack={prev} onSave={handleSalarySave} fromEdit={fromEdit} isLoading={loading?.isSalaryLoad}
        isScrLoading={scrLoading?.isSalaryScrLoad} salaryTemplateList={dropdownList?.salaryTemplateList}
        actualTemplateData={dropdownList?.actualTemplateData} info={currentInfoData}
      />,
    },
    {
      title: 'Issued Documents',
      content: <EmployeeDocument formData={empDocInfo?.emp_issued_document} updateFormData={updateEmpDocData} updateWholeEmpDocData={updateWholeEmpDocData}
        OnBack={prev} onSave={handleIssuedDocSave} fromEdit={fromEdit} isLoading={loading.isIssuedDocLoad} employeeId={employeeId} UpdateGET={updateGet}
        isScrLoading={scrLoading.isIssuedDocScrLoad} OnFinish={handleFinish} />,
    }
  ];

  const [current, setCurrent] = useState(0);
  const [empDetails, setEmployeeDetails] = useState({
    title: "",
    first_name: "",
    last_name: "",
    designation_id: {
      id: "",
      designation_name: ""
    },
    id: "",
    profile: male,
  })

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    marginTop: "8px",
    width: "100%",
    height: "80vh",
    position: "relative"
  };

  const onChange = (value) => {
    switch (value) {
      case 0:
        setCurrent(value)
        getEmployeePersonalInfo(employeeId);
        break
      case 1:
        setCurrent(value)
        getEmployeeDocumentInfo(employeeId);
        break;
      case 2:
        if (personalInfo?.personal_info?.first_name) {
          handlePersonalSave(value);
        }
        setCurrent(value)
        getEmployeeSalaryInfo(employeeId);
        break;
      case 3:
        setCurrent(value)
        break;
      default:
        console.warn(`Unexpected value: ${value}`);
    }
  };

  return (
    <div className='h-full grid place-items-center'>
      <div className='flex' style={{ width: '100%', background: 'white', padding: '1.5dvh', justifyContent: `${current === 0 ? 'space-around' : 'space-between'}`, alignItems: 'center', paddingRight: '5dvw' }}>
        {current !== 0 ? <div style={{ marginRight: "50px" }}><EmployeeDetails details={empDetails} /></div> : null}
        <Steps
          current={current}
          items={items}
          onChange={fromEdit ? onChange : null}
          size="small"
          style={{ width: 750, align: 'center' }}
        />
      </div>
      <div style={contentStyle}>{steps[current].content}</div>
    </div>
  );
}

export default AddEmployeeList
