import React, { useState } from "react";
import { Modal, Table, Tooltip } from "antd";
import moment from "moment";
import {
  EmployeeDetails,
  CommonCarouselPopup,
} from "components/common/CommonComponent";
import dayjs from "dayjs";
import { ImagePaths } from "utils/ImagePath";
import { toIndianCurrency } from "utils/StringUtils";

const ApprovalLogPopUp = ({ TblData, onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isImageModelOpen, setImageModelOpen] = useState(false);
  const tableContainerHeight = "50vh";

  // console.log(TblData, "tblData");

  const handleCancel = () => {
    setIsModalOpen(false);
    onClose();
  };
  const handleImgCancel = () => {
    setImageModelOpen(false);
  };
  const handleModelOpen = (data) => {
    setImageModelOpen(data);
  };
  const columns = [
    {
      title: "Approval Date",
      dataIndex: "approved_date_and_time",
      key: "approved_date_and_time",
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      sortDirections: ["descend", "ascend"],
      width: "18%",
      render: (_, record) => (
        <p style={{ color: "black" }}>
          {record?.approved_date_and_time
            ? moment(
                record?.approved_date_and_time,
                "YYYY-MM-DDTHH:mm:ss"
              ).format("DD-MM-YYYY | hh:mmA")
            : "-"}
        </p>
      ),
    },
    {
      title: "Requested Date & Time",
      dataIndex: "request_date_time",
      key: "request_date_time",
      sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
      sortDirections: ["descend", "ascend"],
      width: "20%",
      render: (_, record) => (
        <p style={{ color: "black" }}>
          {record?.request_date_time
            ? moment(record?.request_date_time, "YYYY-MM-DDTHH:mm:ss").format(
                "DD-MM-YYYY | hh:mmA"
              )
            : "-"}
        </p>
      ),
    },
    {
      title: "Requested Type",
      dataIndex: "request_type",
      key: "request_type",
      sorter: (a, b) => a.requested_type.length - b.requested_type.length,
      sortDirections: ["descend", "ascend"],
      width: "15%",
      render: (_, record) =>
        record?.requested_type?.length > 20 ? (
          <Tooltip title={record?.request_type}>
            {
              <span style={{ color: "black", textTransform: "capitalize" }}>
                {record?.request_type?.slice(0, 20) + "..."}
              </span>
            }
          </Tooltip>
        ) : (
          <p style={{ color: "black", textTransform: "capitalize" }}>
            {record?.request_type
              ? record?.request_type
              : TblData?.approval_type === "Resignation"
              ? "Resignation Request"
              : "-"}
          </p>
        ),
    },
    {
      title: "Status",
      dataIndex: "request__approval_status",
      key: "request__approval_status",
      sorter: (a, b) =>
        a.request__approval_status.length - b.request__approval_status.length,
      sortDirections: ["descend", "ascend"],
      width: "12%",
      render: (_, record) =>
        record?.request__approval_status?.length > 20 ? (
          <Tooltip title={record?.request__approval_status}>
            {
              <span style={{ color: "black", textTransform: "capitalize" }}>
                {record?.request__approval_status?.slice(0, 20) + "..."}
              </span>
            }
          </Tooltip>
        ) : (
          <p style={{ color: "black", textTransform: "capitalize" }}>
            {record?.request__approval_status ? record?.request__approval_status : "-"}
          </p>
        ),
    },
    // {
    //   title: "Date & Time",
    //   dataIndex: "approved_date_and_time",
    //   key: "approved_date_and_time",
    //   sorter: (a, b) => moment(a.date).unix() - moment(b.date).unix(),
    //   sortDirections: ["descend", "ascend"],
    //   width: "18%",
    //   render: (_, record) => (
    //     <p style={{ color: "black" }}>
    //       {record?.approved_date_and_time
    //         ? moment(record?.approved_date_and_time, "YYYY-MM-DDTHH:mm:ss").format(
    //             "DD-MM-YYYY | hh:mmA"
    //           )
    //         : "-"}
    //     </p>
    //   ),
    // },
    {
      title: "Updated By",
      dataIndex: "approver_name",
      key: "approver_name",
      sorter: (a, b) => a.approver_name.length - b.approver_name.length,
      sortDirections: ["descend", "ascend"],
      width: "20%",
      render: (_, record) =>
        record?.approver_name?.length > 28 ? (
          <Tooltip title={record?.approver_name}>
            {
              <span style={{ color: "black" }}>
                {record?.approver_name?.slice(0, 25) + "..."}
              </span>
            }
          </Tooltip>
        ) : (
          <p style={{ color: "black" }}>
            {record?.approver_name ? record?.approver_name : "-"}
          </p>
        ),
    },
  ];

  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  };
  let { employee: employee_details } = TblData;
  let empDetails = {
    ...TblData,
    title: employee_details?.title ? employee_details?.title : "",
    first_name: employee_details?.employee_name
      ? employee_details?.employee_name
      : "",
    last_name: "",
    designation_id: {
      id: employee_details?.designation?.id
        ? employee_details?.designation?.id
        : "",
      designation_name: employee_details?.designation?.name
        ? employee_details?.designation?.name
        : "",
    },
    id: employee_details?.employee_id,
    profile: employee_details?.profile,
  };

  return (
    <Modal
      title=""
      centered
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
      width={"65vw"}
      className="custom-modal"
    >
      <div style={{ width: "100%" }}>
        <p className="common_add_tltTxt border-b mb-5">Approval Log</p>
        {TblData.approval_type !== "Resignation" ? (
          <div className="flex gap-4">
            <div className="" style={{ width: "22%" }}>
              <label className="common_employee_desTxt">Employee Details</label>
              <EmployeeDetails details={empDetails} />
            </div>
            <div className="" style={{ width: "18%" }}>
              <label className="common_employee_desTxt">{`${
                TblData.approval_type === "Leave"
                  ? "Request Details"
                  : TblData.approval_type === "Advance Salary"
                  ? "EMI Period"
                  : TblData.approval_type === "Reimbursements"
                  ? "Reimbursement Type"
                  : ""
              }  `}</label>
              {TblData.approval_type === "Leave" ? (
                <div className="common_employee_nameTxt my-2">
                  {TblData?.leave_type ? TblData?.leave_type : ""}
                </div>
              ) : TblData.approval_type === "Advance Salary" ? (
                <div>
                  <div className="common_employee_nameTxt">
                    {TblData?.emi_period ? TblData?.emi_period : "-"}{" "}
                    <span> Months</span>
                  </div>
                  <div>
                    <span className="common_employee_nameTxt">
                      Debit From :
                    </span>
                    {TblData?.debit_from
                      ? dayjs(TblData?.debit_from).format("DD/MM/YYYY")
                      : "-"}
                  </div>
                </div>
              ) : TblData.approval_type === "Reimbursements" ? (
                <div className="common_employee_nameTxt">
                  {TblData?.reimbursement_type
                    ? TblData?.reimbursement_type
                    : "-"}
                </div>
              ) : (
                <p>{"-"}</p>
              )}
            </div>
            <div className="" style={{ width: "20%" }}>
              <label className="common_employee_desTxt">{`${
                TblData.approval_type === "Leave"
                  ? "Leave Details"
                  : TblData.approval_type === "Advance Salary"
                  ? "Amount Details"
                  : TblData.approval_type === "Reimbursements"
                  ? "Amount Details"
                  : ""
              }  `}</label>
              {TblData.approval_type === "Leave" ? (
                <div className="">
                  <div>
                    <span className="common_employee_nameTxt">
                      From Date :{" "}
                    </span>
                    <span className="common_cancelTxt">
                      {TblData?.from_date
                        ? dayjs(TblData?.from_date).format("DD/MM/YYYY")
                        : ""}{" "}
                    </span>
                  </div>
                  <div>
                    <span className="common_employee_nameTxt">To Date : </span>
                    {TblData?.to_date
                      ? dayjs(TblData?.to_date).format("DD/MM/YYYY")
                      : ""}
                  </div>
                </div>
              ) : TblData.approval_type === "Advance Salary" ||
                TblData.approval_type === "Reimbursements" ? (
                <div>
                  <div>
                    <span className="common_employee_nameTxt">Request : </span>
                    <span className="common_cancelTxt">
                      {TblData?.requested_amount
                        ? toIndianCurrency(TblData?.requested_amount)
                        : "-"}{" "}
                    </span>
                  </div>
                  <div>
                    <span className="common_employee_nameTxt">Approved : </span>
                    {TblData?.approval_amount
                      ? toIndianCurrency(TblData?.approval_amount)
                      : TblData?.approved_amount
                      ? toIndianCurrency(TblData?.approval_amount)
                      : "-"}
                  </div>
                </div>
              ) : (
                <p>{"-"}</p>
              )}
            </div>
            <div className="" style={{ width: "20%" }}>
              <label className="common_employee_desTxt">{`${
                TblData.approval_type === "Reimbursements"
                  ? "Description"
                  : "Reason"
              }`}</label>
              <div className="my-2">
                {TblData?.reason ? TblData?.reason : "-"}
              </div>
            </div>
            <div className="" style={{ width: "15%" }}>
              <label className="common_employee_desTxt">Attachments</label>
              {TblData?.attachments?.length > 0 ? (
                <div>
                  <a
                    onClick={() => {
                      TblData?.attachments
                        ? handleModelOpen(true)
                        : handleModelOpen(false);
                    }}
                  >
                    <span className="flex py-2" style={{ color: "#04B7B1" }}>
                      <img src={ImagePaths.attachment_line.default} /> View
                      Attachments
                    </span>
                  </a>
                </div>
              ) : (
                <p className="my-2">-</p>
              )}
            </div>
          </div>
        ) : (
          <div className="" style={{ width: "22%" }}>
            <label className="common_employee_desTxt">Employee Details</label>
            <EmployeeDetails details={empDetails} />
          </div>
        )}

        <div className={"my-5 w-full"} style={styles.fltContainer}>
          <Table
            columns={columns}
            dataSource={TblData?.approval_log?.length > 0 ? TblData?.approval_log : null}
            style={{
              "--table-container-height": tableContainerHeight,
              "--table-container-footer": "130px",
            }}
            scroll={{
              y:
                TblData?.length > 0
                  ? `calc(${tableContainerHeight} - 130px)`
                  : null,
            }}
            pagination={{
              style: { marginRight: 20, padding: 0 },
              showSizeChanger: false,
              showQuickJumper: true,
            }}
          />
        </div>
      </div>
      {isImageModelOpen && TblData?.attachments?.length > 0 ? (
        <Modal
          centered
          open={isImageModelOpen}
          onCancel={handleImgCancel}
          footer={null}
          width={"50vw"}
        >
          <CommonCarouselPopup documents={[...TblData?.attachments]} />
        </Modal>
      ) : null}
    </Modal>
  );
};

export default ApprovalLogPopUp;
