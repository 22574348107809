import { Card, Dropdown, Input, Modal, Space, Table, Tooltip } from "antd";
import {
  CommonClickableApprovalCards,
  CommonClickableCards,
  CommonDeletePopup,
  EmployeeDetails,
} from "components/common/CommonComponent";
import CommonTblHeader from "components/common/CommonTblHeader";
import { useGetApprovalInbox } from "hooks/api/payrollApi/approvalInboxApi";
import { useCallback, useEffect, useState } from "react";
import PayrollFilter from "../PayRollFilters";
import MyButton from "components/ui/Button/MyButton";
import { ImagePaths } from "utils/ImagePath";
import IconButton from "components/ui/Button/IconButton";
import { MoreOutlined } from "@ant-design/icons";
import {
  getLeaveApprovalPagination,
  handleApprovedStatusById,
} from "hooks/api/payrollApi/leaveApprovalApi";
import useApiData from "components/common/useGetApiData";
import { getAdvanceSalaryApprovalPagination } from "hooks/api/payrollApi/advanceSalaryApprovalApi";
import { getReimbursementApprovalPagination } from "hooks/api/payrollApi/reimbursementApprovalApi";
import { getResignationApproval } from "hooks/api/payrollApi/resignationApprovalApi";
import { render } from "@testing-library/react";
import ToastMsg from "components/common/ToastMsg";
import dayjs, { Dayjs } from "dayjs";
import { toIndianCurrency } from "utils/StringUtils";
import { checkUserPermissionFunc } from "components/common/CommonFuntion";
import ApprovalLogPopUp from "pages/employee/ApprovalLogPopUp";
import { PAYROLL_LEAVE_APPROVAL } from "constants";
import { PAYROLL_REIMBURSEMENT_APPROVAL } from "constants";
import { PAYROLL_ADVANCE_SALARY_APPROVAL } from "constants";
import AddEditLeaveApproval from "./EditLeaveApproval";

const NewApprovalInbox = () => {
  // token and pagination variable
  const authtoken = sessionStorage.getItem("token");
  const companyid = sessionStorage.getItem("userId");
  const tableContainerHeight = "72vh";
  const dateFormat = "YYYY-MM-DD";
  const query = "";
  const pageSize = 10;
  const styles = {
    fltContainer: {
      border: "1px solid #cbcbcb",
      height: tableContainerHeight,
    },
  };

  // states || ref || toast
  const selectedInbox = [
    "Leave Approval",
    "Advance Salary",
    "Reimbursement",
    "Resignation",
  ];
  const [approvalInboxData, setApprovalInboxData] = useState("Leave Approval");

  const selectedFilter = [
    "all",
    "pending",
    "approved",
    "rejected",
    "cancelled",
  ];
  const [selectedFilterVal, setSelectedFilterVal] = useState("all");

  // filter
  const initialData = {
    from_date: "",
    to_date: "",
    leave_type: "",
    employee: "",
    leaveTypeList: [],
  };
  const [filterDatas, setFilterDatas] = useState({ initialData });
  const [filterVisible, setFilterVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState({});
  const [searchText, setSearchText] = useState("");
  const [dataUpdated, setDataUpdated] = useState(false);
  const [reorderedColumns, setReorderedColumns] = useState([]);

  // approval Log
  const [isApprovalLog, setIsApprovalLog] = useState(false);
  const [approvalData, setApprovalData] = useState([]);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [cancelData, setCancelData] = useState([]);

  // edit log
  const [isReasonPopup, setReasonPopup] = useState(false);
  const [updatedStatus, setUpdatedStatus] = useState(null);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [sorting, setSorting] = useState("");

  useEffect(() => {
    if (!authtoken) {
      window.location.href = "/";
    }
  }, [authtoken]);

  // Custom hook data
  const approvalApiMap = {
    "Leave Approval": getLeaveApprovalPagination,
    Reimbursement: getReimbursementApprovalPagination,
    "Advance Salary": getAdvanceSalaryApprovalPagination,
    Resignation: getResignationApproval,
  };

  const searchFilterQuery =
    selectedFilterVal.toLowerCase() === "all"
      ? "" // No filter applied
      : `approval_status=${selectedFilterVal.toLowerCase()}`;

  const searchTextQuery = searchText ? `search=${searchText}` : null;

  const combinedQuery = [searchFilterQuery, searchTextQuery]
    .filter(Boolean)
    .join("&");

  const [data, pageInfo, loading, _message, refetch, extras] = useApiData(
    approvalApiMap[approvalInboxData],
    {
      pageNo,
      pageSize,
      query: combinedQuery,
      searchText,
      sorting,
    }
  );

  const userPermission = checkUserPermissionFunc(
    "employee",
    "Approval Inbox",
    "SubMenu"
  );

  // Trigger API call when approvalInboxData, pageNo, or sorting changes
  useEffect(() => {
    refetch(pageNo, pageSize, sorting);
  }, [approvalInboxData, pageNo, sorting]);

  const handleIndexPageName = (item) => {
    setApprovalInboxData(item);
    setPageNo(1);
  };

  // approval log and cancel log functions
  const handleApprovalLog = (record) => {
    // let data = record?.approval_log?.length > 0 ? record?.approval_log : [];

    setApprovalData(record);
    setIsApprovalLog(true);
  };

  const handleApprovalLogCls = () => {
    setIsApprovalLog(false);
    setApprovalData([]);
  };

  const handleCancelPopup = (record) => {
    setConfirmPopup(true);
    setCancelData(record);
  };

  const handlePopClose = () => {
    setConfirmPopup(false);
  };

  const handleCancelPost = useCallback(() => {
    const authtoken = sessionStorage.getItem("token");
    var API = null;
    var data = cancelData ? cancelData : null;
    try {
      let updatedFormData = {
        approval_notes: "",
        approval_status: "cancelled",
        id: data?.id,
        request_type: "cancel request",
      };
      if (data?.approval_type === "Leave") {
        API = PAYROLL_LEAVE_APPROVAL;
        updatedFormData = {
          ...updatedFormData,
          company_id: companyid,
          from_date: dayjs(data.from_date).format(dateFormat),
          to_date: dayjs(data.to_date).format(dateFormat),
          leave_id: data?.id,
          leave_type: data?.leave_type_id,
          reason: data?.reason,
          uploaded_documents: [],
        };
      } else if (data?.approval_type === "Reimbursements") {
        API = PAYROLL_REIMBURSEMENT_APPROVAL;
        updatedFormData = {
          ...updatedFormData,
          approved_amount: data?.approval_amount,
          reimbursement_type: data?.reimbursement_type_id,
          requested_amount: data?.requested_amount,
        };
      } else {
        API = PAYROLL_ADVANCE_SALARY_APPROVAL;
        updatedFormData = {
          ...updatedFormData,
          advance_requested: data?.requested_amount,
          approved_amount: data?.approval_amount,
          requested_emi_period: data?.emi_period,
          debit_from: data?.debit_from,
        };
      }
      fetch(API, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${authtoken}`,
        },
        body: JSON.stringify(updatedFormData),
      })
        .then((response) => response.json())
        .then(async (data) => {
          if (data.status === "success") {
            ToastMsg("success", data.message);
            setPageNo(1);
            refetch(1, pageSize, query, searchText, sorting);
            setDataUpdated(!dataUpdated);
            handlePopClose();
          } else if (data.status === "fail") {
            ToastMsg("error", data.message);
          }
        })
        .catch((error) => {
          ToastMsg("error", error.message);
        });
    } catch (error) {
      ToastMsg("error", error.message);
    }
  });

  // aprove && reject functions
  const handleSave = () => {
    setEditVisible(false);
    setSelectedDetails({});
    refetch(pageNo, pageSize, query, searchText, sorting);
    setDataUpdated(!dataUpdated);
    setReasonPopup(false);
  };

  const handleCancel = () => {
    setEditVisible(false);
    setSelectedDetails({});
    setReasonPopup(false);
  };

  const handleDescriptionPopup = () => {
    return (
      <Modal
        centered
        open={isReasonPopup}
        onCancel={handleCancel}
        footer={null}
        width={"30vw"}
      >
        <Input.TextArea
          autoComplete="off"
          bordered="true"
          className="mt-7 textarea"
          rows={4}
          autoSize={{
            minRows: 3,
            maxRows: 4,
          }}
          maxLength={150}
          placeholder="Enter reason for Approval Notes for a leave Approval"
          name="approval_notes"
          onChange={(e) => {
            setUpdatedStatus({
              ...updatedStatus,
              approval_notes: e.target.value,
            });
          }}
        />
        <div className="flex justify-end items-end mt-3">
          <MyButton
            htmlType="button"
            label={"Cancel"}
            onClick={handleCancel}
            loading={loading}
            paddingX={"0 1.2vw"}
            marginRight={"0.625vw"}
            testID="asgreg_btn_draft"
          />
          <MyButton
            htmlType="button"
            label={
              updatedStatus?.approval_status === "rejected"
                ? "Reject"
                : "Approve"
            }
            onClick={handleSubmit}
            loading={loading}
            paddingX={"0 1.2vw"}
            bgColor={
              updatedStatus?.approval_status === "rejected"
                ? "#D94854"
                : "#0E862A"
            }
          />
        </div>
      </Modal>
    );
  };

  const handleSubmit = () => {
    handleApprovedStatusById(updatedStatus).then((data) => {
      if (data.status === "success") {
        ToastMsg("success", data.message);
        handleSave();
      } else if (data.status === "fail") {
        ToastMsg("error", data.message);
        handleSave();
      }
    });
  };

  // Conditionally set columns based on the inbox type
  const columns =
    approvalInboxData === "Leave Approval"
      ? [
          {
            title: "Approval Sent",
            dataIndex: "approvalSent",
            key: "approvalSent",
            width: "18%",
            render: (_, record) => {
              let empDetails = {
                ...record.employee,
                designation_id: record?.employee?.designation
                  ? {
                      id: record?.employee?.designation.id,
                      designation_name: record?.employee?.designation?.name,
                    }
                  : { id: 0, designation_name: "" },
                first_name: record?.employee?.employee_name
                  ? record?.employee?.employee_name
                  : "",
                id: record?.employee?.employee_id,
              };
              return (
                <span
                  style={{
                    color: record.is_active ? "black" : "#CACACA",
                    cursor: "pointer",
                  }}
                >
                  <EmployeeDetails details={empDetails} />
                </span>
              );
            },
          },
          {
            title: "From Date",
            dataIndex: "from_date",
            key: "from_date",
            render: (_, record) => {
              return (
                <span>{dayjs(record.from_date).format("DD-MM-YYYY")}</span>
              );
            },
          },
          {
            title: "To Date",
            dataIndex: "to_date",
            key: "to_date",
            render: (_, record) => {
              return <span>{dayjs(record.to_date).format("DD-MM-YYYY")}</span>;
            },
          },
          {
            title: "Leave Type",
            dataIndex: "leave_type",
            key: "leave_type",
            width: "12%",
            render: (_, record) => {
              return <span className=" capitalize">{record.leave_type ? record.leave_type : "-"}</span>;
            },
          },
          {
            title: "Request Type",
            dataIndex: "request_type",
            key: "request_type_name",
            width: "12%",
          },
          {
            title: "Status",
            dataIndex: "approval_status",
            key: "approval_status",
          },
          {
            title: "Approved By",
            render: (record) => {
              return (
                <span>
                  {record.approval_status === "approved" ? (
                    <span className=" capitalize">
                      {record?.approved_by?.employee_name}
                    </span>
                  ) : (
                    "-"
                  )}
                </span>
              );
            },
          },
          { title: "Reason", dataIndex: "reason", key: "reason" },
        ]
      : approvalInboxData === "Advance Salary"
      ? [
          {
            title: "Approval Request Date",
            dataIndex: "request_date",
            key: "date",
            sorter: true,
            width: "17%",
            render: (_, record) => (
              <p>
                {record?.date ? dayjs(record?.date).format("DD/MM/YYYY") : "-"}
              </p>
            ),
          },
          {
            title: "Approval Sent from",
            dataIndex: "sent_from",
            key: "employee__employee_name",
            sorter: true,
            width: "23%",
            render: (_, record) => {
              let empDetails = {
                ...record.employee,
                designation_id: record?.employee?.designation
                  ? {
                      id: record?.employee?.designation.id,
                      designation_name: record?.employee?.designation.name,
                    }
                  : { id: 0, designation_name: "" },
                first_name: record?.employee?.employee_name
                  ? record?.employee?.employee_name
                  : "",
                id: record?.employee?.employee_id,
              };
              return (
                <span
                  style={{
                    color: record.is_active ? "black" : "#CACACA",
                    cursor: "pointer",
                  }}
                >
                  <EmployeeDetails details={empDetails} />
                </span>
              );
            },
          },
          {
            title: "Request Type",
            dataIndex: "request_type",
            key: "request_type_name",
            sorter: true,
            width: "15%",
            fixed: "left",
            render: (_, record) =>
              record?.notes?.length > 20 ? (
                <Tooltip
                  style={{ textTransform: "capitalize" }}
                  title={record?.request_type}
                >
                  {<span>{record?.request_type?.slice(0, 20) + "..."}</span>}
                </Tooltip>
              ) : (
                <p style={{ textTransform: "capitalize" }}>
                  {record?.request_type ? record?.request_type : "-"}
                </p>
              ),
          },
          {
            title: "Request Notes",
            dataIndex: "request_notes",
            key: "reason",
            sorter: true,
            width: "15%",
            fixed: "left",
            render: (_, record) =>
              record?.reason?.length > 28 ? (
                <Tooltip title={record?.reason}>
                  {<span>{record?.reason?.slice(0, 25) + "..."}</span>}
                </Tooltip>
              ) : (
                <p>{record?.reason ? record?.reason : "-"}</p>
              ),
          },
          {
            title: "Request Amount",
            dataIndex: "request_amount",
            key: "advance_requested",
            sorter: true,
            width: "15%",
            render: (_, record) =>
              record?.advance_requested?.length > 7 ? (
                <Tooltip title={record?.advance_requested}>
                  {
                    <span>
                      {record?.advance_requested?.slice(0, 20) + "..."}
                    </span>
                  }
                </Tooltip>
              ) : (
                <p>
                  {record?.advance_requested
                    ? toIndianCurrency(record?.advance_requested)
                    : "-"}
                </p>
              ),
          },
          {
            title: "Status",
            dataIndex: "approval_status",
            key: "approval_status",
            width: "15%",
          },
        ]
      : approvalInboxData === "Reimbursement"
      ? [
          {
            title: "Approval Request Date",
            dataIndex: "date",
            key: "date",
            render: (_, record) => (
              <p>
                {record?.date ? dayjs(record?.date).format("DD/MM/YYYY") : "-"}
              </p>
            ),
          },
          {
            title: "Approval Sent from",
            dataIndex: "send_from",
            key: "employee__employee_name",
            width: "23%",
            fixed: "left",
            render: (_, record) => {
              let empDetails = {
                ...record.employee,
                designation_id: record?.employee?.designation
                  ? {
                      id: record?.employee?.designation.id,
                      designation_name: record?.employee?.designation.name,
                    }
                  : { id: 0, designation_name: "" },
                first_name: record?.employee?.employee_name
                  ? record?.employee?.employee_name
                  : "",
                id: record?.employee?.employee_id,
              };
              return (
                <span
                  style={{
                    color: record.is_active ? "black" : "#CACACA",
                    cursor: "pointer",
                  }}
                >
                  <EmployeeDetails details={empDetails} />
                </span>
              );
            },
          },
          {
            title: "Request Type",
            dataIndex: "request_type",
            key: "request_type_name",
          },
          {
            title: "Request Notes",
            dataIndex: "request_notes",
            key: "description",
            render: (_, record) =>
              record?.description?.length > 28 ? (
                <Tooltip title={record?.description}>
                  {<span>{record?.description?.slice(0, 25) + "..."}</span>}
                </Tooltip>
              ) : (
                <p>{record?.description ? record?.description : "-"}</p>
              ),
          },
          {
            title: "Request Amount",
            dataIndex: "requested_amount",
            key: "requested_amount",
            render: (_, record) =>
              record?.requested_amount?.length > 7 ? (
                <Tooltip title={record?.requested_amount}>
                  {
                    <span>
                      {record?.requested_amount?.slice(0, 20) + "..."}
                    </span>
                  }
                </Tooltip>
              ) : (
                <p>
                  {record?.requested_amount
                    ? toIndianCurrency(record?.requested_amount)
                    : "-"}
                </p>
              ),
          },
          {
            title: "Status",
            dataIndex: "approval_status",
            key: "approval_status",
          },
        ]
      : [
          {
            title: "Employee Details",
            dataIndex: "emp_detail",
            key: "employee__employee_name",
            render: (_, record) => {
              console.log(record, "resignation");

              let empDetails = {
                ...record.employee_details,
                designation_id: record?.employee_details?.designation
                  ? {
                      id: record?.employee_details?.designation.id,
                      designation_name:
                        record?.employee_details?.designation.name,
                    }
                  : { id: 0, designation_name: "" },
                first_name: record?.employee_details?.employee_name
                  ? record?.employee_details?.employee_name
                  : "",
                id: record?.employee_details?.employee_id,
              };

              return (
                <span
                  style={{
                    color: record.is_active ? "black" : "#CACACA",
                    cursor: "pointer",
                  }}
                >
                  <EmployeeDetails details={empDetails} />
                </span>
              );
            },
          },
          {
            title: "Request Date",
            dataIndex: "request_date",
            key: "request_date",
          },
          {
            title: "Reason For Resignation",
            dataIndex: "reason",
            key: "reason",
          },
          {
            title: "Status",
            dataIndex: "approval_status",
            key: "approval_status",
          },
        ];

  if (userPermission?.edit) {
    approvalInboxData === "Leave Approval"
      ? columns.push({
          title: "Action",
          key: "action",
          render: (_, record) => {
            // console.log(record, "record");

            const items = [
              {
                key: "1",
                label: <div className="empApproveTxt">Approval Log</div>,
              },
              {
                key: "2",
                label: <div className="empApproveTxt">Cancel Request</div>,
                disabled:
                  !record?.enable_cancel_request ||
                  record?.approval_status === "cancelled" ||
                  record?.approval_status === "rejected",
              },
            ];

            return (
              <div className="flex items-center gap-5">
                <IconButton
                  className=""
                  title="Request Details"
                  icon={
                    <img
                      src={ImagePaths.ApproveIcon.default}
                      alt="Request Details"
                    />
                  }
                  onClick={() => (loading ? "" : handleEditClick(record))}
                />
                <Space size={"middle"}>
                  <Dropdown
                    menu={{
                      items,
                      onClick: ({ key }) => {
                        if (key === "1") {
                          handleApprovalLog(record);
                        } else {
                          handleCancelPopup(record);
                        }
                      },
                    }}
                    trigger={["click"]}
                    placement="bottomLeft"
                    overlayStyle={styles.dropOverLay}
                  >
                    <IconButton
                      title=""
                      icon={
                        <img src={ImagePaths.moreIcon.default} alt="more" />
                      }
                    />
                  </Dropdown>
                </Space>
              </div>
            );
          },
        })
      : approvalInboxData === "Advance Salary"
      ? columns.push({
          title: "Action",
          key: "action",
          width: "15%",
          render: (_, record) => {
            console.log(record, "record");

            const items = [
              {
                key: "1",
                label: <div className="empApproveTxt">Approval Log</div>,
              },
              {
                key: "2",
                label: <div className="empApproveTxt">Cancel Request</div>,
                disabled:
                  !record?.enable_cancel_request ||
                  record?.approval_status === "cancelled" ||
                  record?.approval_status === "rejected",
              },
            ];

            return (
              <div className="flex items-center gap-5">
                <IconButton
                  className=""
                  title="Request Details"
                  icon={
                    <img
                      src={ImagePaths.ApproveIcon.default}
                      alt="Request Details"
                    />
                  }
                  onClick={() => (loading ? "" : handleEditClick(record))}
                />
                <Space size={"middle"}>
                  <Dropdown
                    menu={{
                      items,
                      onClick: ({ key }) => {
                        if (key === "1") {
                          handleApprovalLog(record);
                        } else {
                          handleCancelPopup(record);
                        }
                      },
                    }}
                    trigger={["click"]}
                    placement="bottomLeft"
                    overlayStyle={styles.dropOverLay}
                  >
                    <IconButton
                      title=""
                      icon={
                        <img src={ImagePaths.moreIcon.default} alt="more" />
                      }
                    />
                  </Dropdown>
                </Space>
              </div>
            );
          },
        })
      : approvalInboxData === "Reimbursement"
      ? columns.push({
          title: "Action",
          key: "action",
          render: (_, record) => {
            console.log(record, "record");

            const items = [
              {
                key: "1",
                label: <div className="empApproveTxt">Approval Log</div>,
              },
              {
                key: "2",
                label: <div className="empApproveTxt">Cancel Request</div>,
                disabled:
                  !record?.enable_cancel_request ||
                  record?.approval_status === "cancelled" ||
                  record?.approval_status === "rejected",
              },
            ];

            return (
              <div className="flex items-center gap-5">
                <IconButton
                  className=""
                  title="Request Details"
                  icon={
                    <img
                      src={ImagePaths.ApproveIcon.default}
                      alt="Request Details"
                    />
                  }
                  onClick={() => (loading ? "" : handleEditClick(record))}
                />
                <Space size={"middle"}>
                  <Dropdown
                    menu={{
                      items,
                      onClick: ({ key }) => {
                        if (key === "1") {
                          handleApprovalLog(record);
                        } else {
                          handleCancelPopup(record);
                        }
                      },
                    }}
                    trigger={["click"]}
                    placement="bottomLeft"
                    overlayStyle={styles.dropOverLay}
                  >
                    <IconButton
                      title=""
                      icon={
                        <img src={ImagePaths.moreIcon.default} alt="more" />
                      }
                    />
                  </Dropdown>
                </Space>
              </div>
            );
          },
        })
      : columns.push({
          title: "Action",
          key: "action",
          render: (_, record) => {
            console.log(record, "record");

            const items = [
              {
                key: "1",
                label: <div className="empApproveTxt">Approval Log</div>,
              },
              {
                key: "2",
                label: <div className="empApproveTxt">Cancel Request</div>,
                disabled:
                  !record?.enable_cancel_request ||
                  record?.approval_status === "cancelled" ||
                  record?.approval_status === "rejected",
              },
            ];

            return (
              <div className="flex items-center gap-5">
                <IconButton
                  className=""
                  title="Request Details"
                  icon={
                    <img
                      src={ImagePaths.ApproveIcon.default}
                      alt="Request Details"
                    />
                  }
                  onClick={() => (loading ? "" : handleEditClick(record))}
                />
                <Space size={"middle"}>
                  <Dropdown
                    menu={{
                      items,
                      onClick: ({ key }) => {
                        if (key === "1") {
                          handleApprovalLog(record);
                        } else {
                          handleCancelPopup(record);
                        }
                      },
                    }}
                    trigger={["click"]}
                    placement="bottomLeft"
                    overlayStyle={styles.dropOverLay}
                  >
                    <IconButton
                      title=""
                      icon={
                        <img src={ImagePaths.moreIcon.default} alt="more" />
                      }
                    />
                  </Dropdown>
                </Space>
              </div>
            );
          },
        });
  }

  const queryString =
    "?page_no=" +
    pageNo +
    "&page_size=" +
    pageSize +
    "&employee=" +
    (filterDatas.employee ? filterDatas.employee : "") +
    "&date=" +
    (filterDatas.requested_date ? filterDatas.requested_date : "") +
    "&search=" +
    searchText +
    (sorting ? `&${sorting}` : "");

  const handleFilter = () => {
    setFilterVisible(true);
  };

  const handleFilterClick = (item) => {
    setSelectedFilterVal(item);
    refetch(data, pageInfo, loading, _message, extras);
  };

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      setPageNo(1);
      refetch(1, pageSize, query, searchText, sorting);
      setDataUpdated(!dataUpdated);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const sortorder = `sort=${
      sorter.order === "descend"
        ? "desc"
        : sorter.order === "ascend"
        ? "asc"
        : ""
    }&`;
    const sortQuery = sorter.order
      ? `${sortorder}column=${sorter.columnKey}`
      : "";
    refetch(pagination.current, pageSize, query, searchText, sortQuery);
    setSorting(sortQuery);
  };

  const handleEditClick = (details) => {
    if (editVisible && Object.keys(selectedDetails).length > 0) {
      ToastMsg("warning", "Please cancel the existing edit operation!");
    } else {
      setEditVisible(true);
      setSelectedDetails(details);
    }
  };

  const handleStateChanges = (search = "", reorderedList = []) => {
    setSearchText(search);
    setReorderedColumns(reorderedList);
  };

  // Filter start
  const renderFilter = (data) => {
    setFilterDatas((prevdata) => ({ ...prevdata, ...data }));
    setPageNo(1);
    refetch(
      1,
      pageSize,
      `leave_id=${data.leave_type ? data.leave_type : ""}&from_date=${
        data.from_date ? data.from_date : ""
      }&to_date=${data.to_date ? data.to_date : ""}&employee=${
        data.employee ? data.employee : ""
      }`,
      searchText,
      sorting
    );
    setFilterVisible(false);
  };

  const ClosePopUp = () => {
    setFilterVisible(false);
  };

  const clearFilter = () => {
    setFilterDatas((prevState) => ({
      ...prevState,
      from_date: "",
      to_date: "",
      leave_type: "",
      employee: "",
    }));
    setPageNo(1);
    refetch(1, pageSize, query, searchText, sorting);
  };

  return (
    <div className="p-2">
      {/* Header */}
      <h1 className=" font-[Urbanist] text-2xl font-medium">Approval Inbox</h1>

      {/* Leave types and request status count */}
      <div className="flex my-1">
        {selectedInbox.map((item, index) => {
          return (
            <CommonClickableApprovalCards
              key={index}
              label={item}
              testID={`${item}-${index}`}
              onClick={() => handleIndexPageName(item)}
              isSelected={approvalInboxData === item}
            />
          );
        })}
      </div>

      <Card className="h-full">
        <div className={"h-1/6"}>
          {/* Common header */}
          <CommonTblHeader
            pageName={approvalInboxData}
            pageInfo={pageInfo}
            columns={columns}
            handleSearch={handleSearch}
            onStateChange={handleStateChanges}
            isAddRequired={false}
            isDraftRequired={false}
            isDraft={false}
            isExcelRequired={false}
            approvalFilter={true}
            selectedFilterData={selectedFilter}
            selectedFilterVal={selectedFilterVal}
            handleFilter={handleFilter}
            handleFilterClick={handleFilterClick}
            query={queryString}
            tableDataCount={data}
            extras={extras}
          />
        </div>

        {/* Filter and table */}
        <div className="h-5/6 flex justify-center items-center mt-1">
          {filterVisible ? (
            <div className="w-1/4 mt-5" style={styles.fltContainer}>
              <PayrollFilter
                pageName={(() => {
                  // Mapping approvalInboxData values to PayrollFilter pageName
                  const pageNameMap = {
                    Reimbursement: "Reimbursements",
                    Resignation: "ResignationApproval",
                    "Leave Approval": "LeaveApproval",
                    "Advance Salary": "AdvanceSalary",
                  };

                  // Return the mapped pageName or a default value
                  return pageNameMap[approvalInboxData] || "Leave Approval";
                })()}
                filterDatas={filterDatas}
                closeFilter={ClosePopUp}
                filteredData={renderFilter}
                clearData={clearFilter}
              />
            </div>
          ) : null}
          <div
            className={filterVisible ? "mt-5 w-3/4" : "mt-5 w-full"}
            style={styles.fltContainer}
          >
            <Table
              columns={
                reorderedColumns?.length > 0 ? reorderedColumns : columns
              }
              dataSource={data?.length > 0 ? data : null}
              loading={loading}
              style={{ "--table-container-height": tableContainerHeight }}
              scroll={{ y: `calc(${tableContainerHeight} - 230px)` }}
              onChange={handleTableChange}
              pagination={{
                style: { marginRight: 20, padding: 0 },
                defaultPageSize: pageSize,
                showSizeChanger: false,
                showQuickJumper: true,
                total: pageInfo?.totalCount ? pageInfo?.totalCount : 0,
                pageSizeOptions: ["10", "20", "30"],
                pageSize,
                defaultCurrent: pageNo,
              }}
              key={dataUpdated ? "updated" : "not-updated"}
            />
          </div>
        </div>

        {/* leave approval options */}
        {confirmPopup && (
          <CommonDeletePopup
            handleCancelBtn={handlePopClose}
            handleDeleteBtn={handleCancelPost}
            sentence={"Are You Sure ? Do you want to cancel?"}
            okBtnLable={"Yes"}
          />
        )}
        {isApprovalLog ? (
          <ApprovalLogPopUp
            TblData={approvalData}
            onClose={handleApprovalLogCls}
          />
        ) : null}

        {/* edit leave request */}
        {isReasonPopup ? handleDescriptionPopup() : null}
        {editVisible && Object.keys(selectedDetails).length > 0 ? (
          <AddEditLeaveApproval
            editDetails={selectedDetails}
            onHide={handleCancel}
            onSave={handleSave}
            fromEdit={editVisible}
            leaveTypeList={
              filterDatas?.leaveTypeList?.length > 0
                ? filterDatas?.leaveTypeList
                : []
            }
            isEdit={userPermission?.edit}
          />
        ) : null}
      </Card>
    </div>
  );
};

export default NewApprovalInbox;
